
export const universities = [
    { title: "All Universities"},
    { title: "University of Benin, Benin-city"},
    { title: "Abia State University, Uturu"},
    { title: "Adamawa State University, Mubi"},
    { title: "Akwa-Ibom State University, Ikot Akpaden"},
    { title: "Bauchi State University, Gadau"},
    { title: "Benue State University, Makurdi"},
    { title: "Delta State University, Abraka"},
    { title: "Ebonyi State University, Abakiliki"},
    { title: "Rivers State University, Rivers"},
    { title: "Ekiti State University, Ekiti"},
    { title: "Gombe State University, Gombe"},
    { title: "Imo State University, Owerri"},
    { title: "Yobe State University, Damaturu"},
    { title: "Lagos State University, Ojo"},
    { title: "Kaduna State University, Kaduna"},
    { title: "Nasarawa State University, Keffi"},
    { title: "Plateau State University, Bokkos"},
    { title: "Kwara State University, Illorin"},
    { title: "Taraba State University, Jalingo"},
    { title: "Osun State University, Osogbo"},
    { title: "Sokoto State University, Sokoto"},
    { title: "Kogi State University, Kabba"},
    { title: "Borno State University, Maiduguri"},
    { title: "Zamfara State University, Zamfara"},
    { title: "Edo State University, Uzairue"},
    { title: "Niger Delta University, Yenagoa"},
    { title: "Cross-River State University of Technology, Calabar"},
    { title: "Ladoke Akintola University of Technology, Ogbomoso"},
    { title: "Kano State University of Science and Technology, Wudil"},
    { title: "Moshood Abiola University of Science and Technology, Abeokuta"},
    { title: "Gombe State University of Science and Technology, Gombe"},
    { title: "Confluence University of Science and Technology, Osara, kogi"},
    { title: "Ondo State University of Science and Technology, Okitipupa"},
    { title: "Delta State University of Science and Technology, Ozoro"},
    { title: "Lagos State University of Science and Technology, Ikorodu"},
    { title: "Ondo State University of Medical Sciences, Ondo"},
    { title: "Lagos State University of Education, Ijanikin"},
    { title: "Bayelsa Medical University, Bayelsa"},
    { title: "Kebbi State University of Science and Technology, Aliero"},
    { title: "Oyo State Technical University , Ibadan"},
    { title: "Enugu State University of Technology, Enugu"},
    { title: "Adekunle Ajasin University, Akungba"},
    { title: "Olabisi Onabanjo University, Ago Iwoye"},
    { title: "Kingsley Ozumba Mbadiwe University, Ogboko, Imo State"},
    { title: "Ibrahim Badamasi Babangida University, Lapai"},
    { title: "Yusuf Maitama Sule University, Kano"},
    { title: "Usumanu Danfodiyo University"},
    { title: "Prince Abubakar Audu University, Anyigba"},
    { title: "Ambrose Alli University, Ekpoma"},
    { title: "Chukwuemeka Odumegwu Ojukwu University, Uli"},
    { title: "Ahmadu Bello University, Zaria"},
    { title: "Obafemi Awolowo University, Ile-Ife"},
    { title: "Nnamdi Azikiwe University, Awka"},
    { title: "Abubakar Tafawa Balewa University, Bauchi"},
    { title: "Alex Ekueme University, Ndufu-Alike, Ebonyi"},
    { title: "Admiralty University, Ibusa, Delta State"},
    { title: "David Nweze Umahi Federal University of Medical Sciences, Uburu"},
    { title: "Umar Musa Yar' Adua University, Katsina"},
    { title: "Bayero University, Kano"},
    { title: "Federal University Gashau, Yobe"},
    { title: "Federal University Dutse, Jigawa"},
    { title: "Federal University Oye-Ekiti, Ekiti"},
    { title: "Federal University of Agriculture, Abeokuta"},
    { title: "Federal University of Transportation, Daura, Katsina"},
    { title: "Federal University of Agriculture, Zuru, Kebbi State"},
    { title: "Federal University Birnin-Kebbi, Kebbi"},
    { title: "Federal University Gusau, Zamfara"},
    { title: "Federal University Wukari, Taraba"},
    { title: "Federal University Dutsin-Ma, Katsina"},
    { title: "Federal University Kashere, Gombe"},
    { title: "Federal University Lafia, Nasarawa"},
    { title: "Federal University Lokoja, Kogi"},
    { title: "Federal University Otuoke, Bayelsa"},
    { title: "Federal University of Petroleum Resources, Effurun"},
    { title: "Federal University of Technology, Akure"},
    { title: "Federal University of Technology, Babura, Jigawa"},
    { title: "Federal University of Technology, Ikot-Abasi, Akwa-Ibom"},
    { title: "Federal University of Health Technology, Otukpo, Benue State"},
    { title: "Federal University of Health Sciences, Ila Orangun, Osun State"},
    { title: "Federal University of Technology, Minna"},
    { title: "Federal University of Technology, Owerri"},
    { title: "Dennis Osadebe University, Asaba"},
    { title: "Bamidele Olumilua University of Science and Technology, Ikere, Ekiti State"},
    { title: "Modibbo Adama University of Technology, Yola"},
    { title: "Michael Okpara University of Agriculture, Umudike"},
    { title: "National Open University of Nigeria, Lagos"},
    { title: "Ignatius Ajuru University of Education, Rumuolumeni"},
    { title: "Tai Solarin University of Education, Ijebu-Ode"},
    { title: "Sa'adatu Rimi University of Education"},
    { title: "Emmanuel Alayande University of Education, Oyo, Oyo State"},
    { title: "Niger State University of Education, Minna"},
    { title: "Shehu Shagari University of Education, Sokoto"},
    { title: "Sule Lamido University, Kafin Hausa, Jigawa"},
    { title: "University of Africa. Toru Orua Bayelsa State"},
    { title: "Enugu State University of Medical and Applied Sciences. Igbo-Eno"},
    { title: "University of Delta. Agbor"},
    { title: "University of Illesa. Osun State"},
    { title: "University of Calabar"},
    { title: "University of Ibadan"},
    { title: "University of Illorin"},
    { title: "University of Jos"},
    { title: "University of Lagos"},
    { title: "University of Maiduguri"},
    { title: "University of Nigeria, Nsukka"},
    { title: "University of Port-Harcourt"},
    { title: "University of Uyo"},
    { title: "University of Agriculture, Makurdi"},
    { title: "Nigeria Police Academy. Wudil"},
    { title: "Nigeria Defence Academy. Kaduna"},
    { title: "University of Abuja. Gwagwalada"},
    { title: "Nigerian Maritime University, Okerenkoko, Delta State"},
    { title: "Nigerian Army University, BIU"},
    { title: "Airforce Institute of Technology. Kaduna"},
    { title: "Achievers University, Owo"},
    { title: "Adeleke University, Ede"},
    { title: "Afe Babalola University, Ado-Ekiti, Ekiti State"},
    { title: "African University of Science & Technology, Abuja"},
    { title: "Ajayi Crowther University, Ibadan"},
    { title: "Al-Hikmah University, Ilorin"},
    { title: "Al-Qalam University, Katsina"},
    { title: "American University of Nigeria, Yola"},
    { title: "Augustine University"},
    { title: "Babcock University,Ilishan-Remo"},
    { title: "Baze University"},
    { title: "Bells University of Technology, Otta"},
    { title: "Benson Idahosa University, Benin City"},
    { title: "Bingham University, New Karu"},
    { title: "Bowen University, Iwo"},
    { title: "Caleb University, Lagos"},
    { title: "Caritas University, Enugu"},
    { title: "Covenant University Ota"},
    { title: "Crawford University Igbesa"},
    { title: "Crescent University"},
    { title: "Edwin Clark University, Kaigbodo"},
    { title: "Elizade University, Ilara-Mokin"},
    { title: "Evangel University, Akaeze"},
    { title: "Fountain Unveristy, Oshogbo"},
    { title: "Godfrey Okoye University, Ugwuomu-Nike – Enugu State"},
    { title: "Gregory University, Uturu"},
    { title: "Joseph Ayo Babalola University, Ikeji-Arakeji"},
    { title: "Hallmark University, Ijebi Itele, Ogun"},
    { title: "Hezekiah University, Umudi"},
    { title: "Igbinedion University Okada"},
    { title: "Kings University, Ode Omu"},
    { title: "Kwararafa University, Wukari"},
    { title: "Landmark University, Omu-Aran"},
    { title: "Lead City University, Ibadan"},
    { title: "Madonna University, Okija"},
    { title: "Mcpherson University, Seriki Sotayo, Ajebo"},
    { title: "Micheal & Cecilia Ibru University"},
    { title: "Mountain Top University"},
    { title: "Nile University of Nigeria, Abuja"},
    { title: "Novena University, Ogume"},
    { title: "Obong University, Obong Ntak"},
    { title: "Oduduwa University, Ipetumodu, Osun State"},
    { title: "Pan-Atlantic University, Lagos"},
    { title: "Paul University, Awka, Anambra State"},
    { title: "Redeemers University, Ede"},
    { title: "Renaissance University, Enugu"},
    { title: "Rhema University, Obeama-Asa, Rivers State"},
    { title: "Ritman University, Ikot Ekpene, Akwa Ibom"},
    { title: "Salem University, Lokoja"},
    { title: "Samuel Adegboyega University, Ogwa"},
    { title: "Southwestern University, Oku Owa"},
    { title: "Summit University, Offa"},
    { title: "Tansian University, Umunya"},
    { title: "University of Mkar, Mkar"},
    { title: "Veritas University, Abuja"},
    { title: "Wellspring University, Evbuobanosa, Edo State"},
    { title: "Wesley University Ondo"},
    { title: "Western Delta University, Oghara Delta State"},
    { title: "Christopher University Mowe"},
    { title: "Kola Daisi University Ibadan, Oyo State"},
    { title: "Anchor University Ayobo Lagos State"},
    { title: "Dominican University Ibadan Oyo State"},
    { title: "Dominion University Ibadan, Oyo State"},
    { title: "Trinity University Ogun State"},
    { title: "Huda University, Gusau, Zamafara State"},
    { title: "Westland University Iwo, Osun State"},
    { title: "Greenfield University, Kaduna"},
    { title: "Skyline University, Kano"},
    { title: "Eko University of Medical and Health Sciences Ijanikin, Lagos"},
    { title: "Atiba University Oyo"},
    { title: "PAMO University of Medical Sciences, Portharcourt"},
    { title: "Precious Cornerstone University, Oyo"},
    { title: "Spiritan University, Nneochi Abia State"},
    { title: "Clifford University Owerrinta Abia State"},
    { title: "Coal City University Enugu State"},
    { title: "Crown Hill University Eiyenkorin, Kwara State"},
    { title: "Arthur Javis University Akpoyubo Cross river State"},
    { title: "Legacy University, Okija Anambra State"},
    { title: "Topfaith University, Mkpatak, Akwa Ibom State"},   	
    { title: "Thomas Adewumi University, Oko-Irese, Kwara State"},   	
    { title: "Maranathan University, Mgbidi, Imo State"},   	
    { title: "Ave Maria University, Piyanko, Nasarawa State"},   	
    { title: "Al-Istiqama University, Sumaila, Kano State"},   	
    { title: "Mudiame University, Irrua, Edo State"},   	
    { title: "Havilla University, Nde-Ikom, Cross River State"},   	
    { title: "Claretian University of Nigeria, Nekede, Imo State"},   	
    { title: "NOK University, Kachia, Kaduna State"},   	
    { title: "Karl-Kumm University, Vom, Plateau State"},   	
    { title: "James Hope University, Lagos, Lagos State"},   	
    { title: "Maryam Abacha American University of Nigeria, Kano State"},   	
    { title: "Capital City University, Kano State"},   	
    { title: "Ahman Pategi University, Kwara State"},   	
    { title: "University of Offa, Kwara State"},   	
    { title: "Mewar International University, Masaka, Nasarawa State"},   	
    { title: "Edusoko University, Bida, Niger State"},   	
    { title: "Philomath University, Kuje, Abuja"},   	
    { title: "Khadija University, Majia, Jigawa State"},   	
    { title: "Anan University, Kwall, Plateau State"},   	
    { title: "PEN Resource University, Gombe"},   	
    { title: "Al-Ansar University, Maiduguri, Borno"},   	
    { title: "Margaret Lawrence University, Umunede, Delta State"},   	
    { title: "Khalifa Isiyaku Rabiu University, Kano"},   	
    { title: "Sports University, Idumuje, Ugboko, Delta State"},   	
    { title: "Baba Ahmed University, Kano State"},   	
    { title: "Saisa University of Medical Sciences and Technology, Sokoto State"},   	
    { title: "Nigerian British University, Asa, Abia State"},   	
    { title: "Peter University, Achina-Onneh Anambra State"},   	
    { title: "Newgate University, MInna, Niger State"},   	
    { title: "European University of Nigeria, Duboyi, FCT"},   	
    { title: "NorthWest University Sokoto State"},   	
    { title: "Rayhaan University, Kebbi"},   	
    { title: "Muhammad Kamalud University Kwara"},   	
    { title: "Sam Maris University, Ondo"},   	
    { title: "Augustine University"},   	
    { title: "Augustine University"},   	
    { title: "Augustine University"},   	
    { title: "Augustine University"},   	
    { title: "Augustine University"},   	
    { title: "Augustine University"},   	
    { title: "Augustine University"},   	
    { title: "Augustine University"},   	
    { title: "Aletheia University, Ago-Iwoye Ogun State"},   	
    { title: "Lux Mundi University Umuahia, Abia State"},   	
    { title: "Maduka University, Ekwegbe, Enugu State"},   	
    { title: "PeaceLand University, Enugu State"},   	
    { title: "Amadeus University, Amizi, Abia State"},   	
    { title: "Vision University, Ikogbo, Ogun State"},   	
    { title: "Azman University, Kano State"},   	
    { title: "Franco British International University, Kaduna State"},   	
    { title: "Gerar University of Medical Science Imope Ijebu, Ogun State"},   	
    { title: "Canadian University of Nigeria, Abuja"},   	
    { title: "British Canadian University, Obufu Cross River State"},   	
    { title: "Hensard University, Toru-Orua, Sagbama, Bayelsa State"},   	
    { title: "Amaj University, Kwali, Abuja"},   	
    { title: "Phoenix University, Agwada, Nasarawa State"},   	
    { title: "Wigwe University, Isiokpo Rivers State"},   	
    { title: "Hillside University of Science and Technology, Okemisi, Ekiti State"},   	
    { title: "University on the Niger, Umunya, Anambra state"},   	
    { title: "Elrazi Medical University Yargaya University, Kano State"},   	
    { title: "Venite University, Iloro-Ekiti, Ekiti State"},   	
    { title: "Shanahan University Onitsha, Anambra State"},   	
    { title: "The Duke Medical University, Calabar, Cross River State"},   	
    { title: "Mercy Medical University, Iwo, Ogun State"},   	
    { title: "Cosmopolitan University Abuja"},   	
    { title: "Miva Open University, Abuja FCT"},   	
    { title: "Iconic Open University, Sokoto State"},   	
    { title: "West Midlands Open University, Ibadan, Oyo State"},   	  	
    { title: "Nigerian University of Technology and Management, Apapa, Lagos State"},   	
    { title: "Prime University, Kuje, FCT Abuja"},   	
    { title: "Jewel University, Gombe state"},   	
    { title: "College of Petroleum and Energy Studies, Kaduna State"},   	
    { title: "El-Amin University, Minna, Niger State"},   	
    { title: "Al-Muhibbah Open University, Abuja"},   	
    { title: "Al-Bayan University, Ankpa, Kogi State"},   	
    { title: "Akanu Ibiam Federal Polytechnic Unwana"},   	
    { title: "Auchi Polytechnic, Auchi, Edo State"},   	
    { title: "Federal Polytechnic Ado Ekiti, Ekiti State"},   	
    { title: "Federal Polytechnic Bali, Taraba State"},   	
    { title: "Federal Polytechnic Bauchi, P.M. B 231, Bauchi State"},   	
    { title: "Federal Polytechnic Bida, P.M.B 55, Niger State"},   	
    { title: "Federal Polytechnic Damaturu, P.M.B 1006, Yobe State"},   	
    { title: "Federal Polytechnic, Daura, Katsina State"},   	
    { title: "Federal Polytechnic Ede, Osun State"},   	
    { title: "Federal Polytechnic Ekowe, Bayelsa State"},   	
    { title: "Federal Polytechnic Idah P.M.B 1035, Kogi State"},   	
    { title: "Federal Polytechnic Ilaro, P.M.B 50, Ogun State"},   	
    { title: "Federal Polytechnic Ile-Oluji, Ondo State"},   	
    { title: "Federal Polytechnic Kaura Namoda, P.M.B, 1012, Zamfara State"},   	
    { title: "Federal Polytechnic Kaltungo, Gombe State"},   	
    { title: "Federal Polytechnic Mubi, PMB 35 Mubi, Adamawa State"},   	
    { title: "Federal Polytechnic Nasarawa, P.M.B. 01 Nasarawa State"},   	
    { title: "Federal Polytechnic Nekede, P.M.B 1036, Owerri, Imo State"},   	
    { title: "Federal Polytechnic Offa, P.M.B 420, Kwara State"},   	
    { title: "Federal Polytechnic Oko, Anambra State"},   	
    { title: "Federal Polytechnic of Oil and Gas Bonny, Rivers State"},   	
    { title: "Federal Polytechnic Ukana,P.M.B 2014Akwa Ibom State"},   	
    { title: "Hussaini Adamu Federal Polytechnic, P.M.B 5004, Kazaure Jigawa State"},   	
    { title: "Kaduna Polytechnic, Kaduna"},   	
    { title: "National Institute of Construction Technology Uromi"},   	
    { title: "Waziri Umaru Federal Polytechnic, P.M.B. 1034, Birnin Kebbi"},   	
    { title: "Yaba College of Technology, P.M.B. 2011, Yaba, Lagos State"},   	
    { title: "Federal Polytechnic Ayede, Oyo State"},   	
    { title: "Federal Polytechnic Munguno, Borno State"},   	
    { title: "Federal Polytechnic Nyak, Shendam, Plateau State"},   	
    { title: "Federal Polytechnic Ohodo, Enugu State"},   	
    { title: "Federal Polytechnic Ugep, Cross Rivers State"},   	
    { title: "Federal Polytechnic Wannune, Benue State"},   	
    { title: "Airforce Institute of Technology (AFIT),NAF Base Kaduna"},   	
    { title: "Petroleum Training Institute Effurun. Delta State"},   	
    { title: "Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi"},   	
    { title: "Nigerian College of Aviation Technology (NCAT), Sokoto Road, Zaria, Kaduna"},   	
    { title: "Abdu Gusau Polytechnic, Talata Mafara P.M.B. 1021, Talata Mafara, Zamfara State"},   	
    { title: "Abia State Polytechnic, Aba,P.M.B. 7166, Aba, Abia State"},   	
    { title: "Abraham Adesanya Polytechnic, Dogbolu/Akanran Ibadan Road, Atikori, Ijebu Igbo"},   	
    { title: "Abubakar Tatari Ali Polytechnic, Bauchi,Jos Rd, Bauchi, Bauchi State"},   	
    { title: "Adamawa State Polytechnic, Yola,P.M.B. 2146, Yola,Adamawa State"},   	
    { title: "Adeseun Ogundoyin Polytechnic, Eruwa(Formerly Ibarapa Polytechnic Eruwa), Oyo State"},   	
    { title: "Akwa Ibom State College of Art & Science, Nung Ukim,P.M.B. 1044, Akwa Ibom State"},   	
    { title: "Akwa Ibom State Polytechnic,Ikot Osurua, Ikot -Ekpene,Akwa Ibom State"},   	
    { title: "Anambra State Polytechnic, PMB 002, Mgbakwu,  Anambra State"},   	
    { title: "Bayelsa State Polytechnic, Aleibiri, P.M.B 168 Ekeremor"},   	
    { title: "Benue State Polytechnic, Ugbokolo,P.M.B. 01 Ugbokolo, Benue State"},   	
    { title: "Binyaminu Usman Polytechnic, Hadejia,Jigawa State"},   	
    { title: "Cross River State Institute of Technology And Management (CRITM) Km 95, Ikom-Calabar Highway"},   	
    { title: "D.S. Adegbenro ICT Polytechnic, Itori-Ewekoro,P.M.B. 5026, Ifo, Ogun State"},   	
    { title: "Delta State Polytechnic, Ogwashi-Uku,P.M.B. 1030, Ogwashi-Uku,Delta State"},   	
    { title: "Delta State Polytechnic, Otefe-Oghara, P.M.B. 03, Otefe-Oghara, Delta State"},   	
    { title: "Delta State Polytechnic, Ozoro,P.M.B. 05, Ozoro, Delta State"},   	
    { title: "Delta State School of Marine Technology, Burutu,P.M.B. 1060, Warri, Delta"},   	
    { title: "Edo State Polytechnic, Usen.P.M.B. 1104, Benin City, Edo State"},   	
    { title: "Ekiti State College of Agriculture and Technology, Isan Ekiti, Ekiti State"},   	
    { title: "Enugu State Polytechnic, Iwollo"},   	
    { title: "Hassan Usman Katsina Polytechnic (HUK),P.M.B. 2052, Katsina, Nigeria"},   	
    { title: "Gateway Polytechnic, SaapadeProf. Wole Soyinka Way, Ogun State"},   	
    { title: "Imo State Polytechnic, Umuagwo, Ohaji, P.M.B. 1472, Umuagwo, Ohaji Owerri,Imo State"},   	
    { title: "Institute of Management and Technology, Enugu,P.M.B 01079, Enugu, Enugu State"},   	
    { title: "Isa Mustapha Agwai Polytechnic Lafia"},   	
    { title: "Jigawa State Polytechnic, Dutse, Jigawa State"},   	
    { title: "Kano State Polytechnic, Kano"},   	
    { title: "Ken Sarowiwa Polytechnic, Bori, Rivers State"},   	
    { title: "Kogi State Polytechnic, Lokoja, Kogi State"},   	
    { title: "Kwara State Polytechnic, Ilorin, Kwara State"},   	
    { title: "Lagos State Polytechnic, Ikorodu"},   	
    { title: "	Mai-Idris Alooma Polytechnic, Geidam, Yobe State"},   	
    { title: "Moshood Abiola Polytechnic, Abeokuta"},   	
    { title: "Nasarawa State College of Agriculture and Technology, Lafia"},   	
    { title: "Niger State Polytechnic, Zungeru"},   	
    { title: "Nuhu Bamalli Polytechnic, Zaria,"},   	
    { title: "Ogun State Institute of Technology, Igbesa"},   	
    { title: "Ogun State Polytechnic, Ipokia"},   	
    { title: "Oke-Ogun Polytechnic, Shaki,Oyo State"},   	
    { title: "Osun State College of Technology,P.M.B. 1011, Esa  Oke, Osun State"},   	
    { title: "Osun State Polytechnic, Iree,P.M.B. 301, Iree, Osun State"},   	
    { title: "Oyo State College of Agriculture and Technology, Igbo Ora"},   	
    { title: "Plateau State Polytechnic, Barkin-Ladi,P.M.B. 02023, Jos, Plateau State"},   	
    { title: "Port-Harcourt PolytechnicP.M.B. 5936, Port-Harcourt,Rivers State"},   	
    { title: "Ramat Polytechnic, Maiduguri"},   	
    { title: "Rufus Giwa Polytechnic, Owo,P.M.B. 1019 Owo Ondo State"},   	
    { title: "Taraba State Polytechnic, Suntai"},   	   	
    { title: "The Polytechnic Ibadan,P.M.B. 22, Ibadan, Oyo State"},   	
    { title: "Umaru Ali Shinkafi Polytechnic, Sokoto"},   	
    { title: "Zamfara State College of Arts and Science, Sani Abacha Way, Sokoto Road, Gusau"},   	
    { title: "Al-Hikma Polytechnic Karu Mararaba Gurku, Karu, Nasarawa State"},   	
    { title: "Allover Central Polytechnic, Sango Ota,Sango Ota, Ogun State"},   	
    { title: "Ajayi Polytechnic, Ikere Ekiti, Ekiti State"},   	
    { title: "Ashi Polytechnic, Benue State"},   	
    { title: "Best Solution Polytechnic, Akure"},   	
    { title: "Bellarks Polytechnic, Kwale, Delta State"},   	
    { title: "Bolmor Polytechnic, Oyo State"},   	
    { title: "Citi Polytechnic, Dutse Alhaji, FCT Abuja"},   	
    { title: "Coastal Polytechnic Apapa, Apapa Lagos"},   	
    { title: "Covenant Polytechnic, Aba, Abia State"},   	
    { title: "Crown Polytechnic, Ado-Ekiti, Ekiti State"},   	
    { title: "Dorben Polytechnic, Abuja, Garki, Bwari, Abuja"},   	
    { title: "Eastern Polytechnic, Rumuokwurusi, Port Harcourt, Rivers State"},   	
    { title: "Enville Institute of Management and Technology, Ikeja, Lagos"},   	
    { title: "Fidei Polytechnic, Gboko, Benue State"},   	
    { title: "Gboko Polytechnic, Gboko, Benue State"},   	
    { title: "Global Polytechnic, Akure, Ondo State"},   	
    { title: "Grace Polytechnic, Surulere, Lagos State"},   	
    { title: "Graceland Polytechnic, Offa, Kwara State"},   	
    { title: "Grundtvig Polytechnic, Oba Anambra State"},   	
    { title: "Harry Pass Polytechnic, Benue State"},   	
    { title: "Heritage Polytechnic, Ikot Udota, Eket, Akwa Ibom State"},   	
    { title: "Ibadan City Polytechnic, Ibadan, Oyo State"},   	
    { title: "I-CON Universal Polytechnic Osogbo, Osun State"},   	
    { title: "Igbajo Polytechnic, Igbajo, Osun State"},   	
    { title: "Interlink Polytechnic, Ijebu-Jesa, Osun State"},   	
    { title: "Iwo City Polytechnic, Iwo, Osun State"},   	
    { title: "Kings Polytechnic, Ubiaja, Edo State"},   	
    { title: "Ihechukwu Madubuike Institute of Technology,Isuochi, Abia State"},   	
    { title: "Kalac Christal Polytechnic, Sangotedo, Lekki, Lagos State"},   	
    { title: "Landmark Polytechnic, Ogun State"},   	
    { title: "Lagos City Polytechnic, Ikeja, Lagos State"},   	
    { title: "Lens Polytechnic, Offa, Kwara State"},   	
    { title: "Lighthouse Polytechnic, Benin City, Edo State"},   	
    { title: "Marist Polytechnic, Umuchigbo, Iji-Nike, Enugu State"},   	
    { title: "Mater Dei Polytechnic,  Enugu State"},   	
    { title: "Nacabs Polytechnic, Akwanga, Nasarawa State"},   	
    { title: "Nogak Polytechnic, Ikom, Cross Rivers State"},   	
    { title: "Novelty Polytechnic Kishi, Oyo State"},   	
    { title: "Our Saviour Institute of Science, Agriculture & Technology, Enugu State"},   	
    { title: "Redeemers College of Technology and Management (RECTEM), Ogun State"},   	
    { title: "Ronik Polytechnic, Ikeja, Lagos State"},   	
    { title: "Saf Polytechnic, Oyo State"},   	
    { title: "Savanah Institute of Technology, Abakaliki, Ebonyi State"},   	
    { title: "Shaka Polytechnic, Benin City, Edo State"},   	
    { title: "Speedway Polytechnic, Osoba Ojudu, Ogun State"},   	
    { title: "St. Mary Polytechnic, Suleja, Niger State"},   	
    { title: "Stars Polytechnic, Ogun State"},   	
    { title: "Southern Atlantic Polytechnic, Uyo, Akwa Ibom State"},   	
    { title: "Sure Foundation Polytechnic, Ikot Akai, Akwa Ibom State"},   	
    { title: "Temple Gate Polytechnic, Aba, Abia State"},   	
    { title: "The Polytechnic, Igbo-Owu, Kwara State"},   	
    { title: "The Polytechnic, Ile Ife, Osun State"},   	
    { title: "The Polytechnic Iresi, Osun State"},   	
    { title: "Timeon Kairos Polytechnic Lagos, Abule Egba Lagos"},   	
    { title: "Tower Polytechnic, Ibadan, Oyo State"},   	
    { title: "Trinity Polytechnic Uyo, Akwa Ibom State"},   	
    { title: "Uma Ukpai Polytechnic, Asaga Ohafia, Abia State"},   	
    { title: "Uyo City Polytechnic, Uyo, Akwa Ibom State"},   	
    { title: "The Polytechnic Otada Adoka, Benue State"},   	
    { title: "Valley View Polytechnic, Ohafia, Abia State"},   	
    { title: "Villanova Polytechnic, Osun State"},   	
    { title: "Wolex Polytechnic, Iwo, Osun State"},   	  	
    { title: "College of Education (Technical), Lafiagi"},   	  	
    { title: "Federal College of Education, Asaba"},   	  	
    { title: "Federal College of Education, Kano"},   	  	
    { title: "Federal College of Education, Oyo"},   	  	
    { title: "College of Education, Gindiri"},   	  	
    { title: "College of Education, Hong"},   	  	
    { title: "Tai Solarin College of Education, Ijebu-Ode"},   	  	
    { title: "College of Education, Ikere-Ekiti"},   	  	
    { title: "Ebonyi State College of Education"},   	  	
    { title: "College of Education, Warri"},   	  	
    { title: "FCT College of Education, Zuba"},   	  	
    { title: "Delar College of Education"},   	  	
    { title: "Federal College of Education, Abeokuta"},   	  	
    { title: "Federal College of Education, Eha-Amufu"},   	  	
    { title: "Federal College of Education, Gombe"},   	  	
    { title: "Federal College of Education, Kontagora"},   	  	
    { title: "Federal College of Education, Okene"},   	  	
    { title: "Federal College of Education , Omoku"},   	  	
    { title: "Federal College of Education , Potiskum"},   	  	
    { title: "Nasarrawa State College of Education, Akwanga"},   	  	
    { title: "Isa Kaita College of Education, Dutsin-Ma"},   	  	
    { title: "College of Education, Ekiadolor-Benin"},   	  	
    { title: "College of Education, Gashua, Damaturu"},   	  	
    { title: "Kaduna State College of Education, Gidan-Waya, Kafanchan"},   	  	
    { title: "Osun State College of Education, Ilesa"},   	  	
    { title: "College of Education Oro"},   	  	
    { title: "College of Education, katsina-Ala"},   	  	
    { title: "Saadatu Rimi College of Education, Kumbotso, Kano"},   	  	
    { title: "Nwafor Orizu College of Education, Nsugbe"},   	  	
    { title: "Adeniran Ogunsanya College of Education, Otto/Ijanikin"},   	  	
    { title: "Alvan Ikoku College of Education, Owerri"},   	  	
    { title: "Emmanuel Alayande College of Education (EACOED), Oyo"},   	  	
    { title: "College of Education, Waka BIU"},   	  	
    { title: "City College of Education, Mararaba"},   	  	
    { title: "Federal College of Education, Akoka"},   	  	
    { title: "Federal College of Education, Bichi"},   	  	
    { title: "Federal College of Education, Gusau"},   	  	
    { title: "Federal College of Education, Katsina"},   	  	
    { title: "Federal College of Education, Obudu"},   	  	
    { title: "Adeyemi College of Education, Ondo"},   	  	
    { title: "Federal College of Education, Pankshin"},   	  	
    { title: "Federal College of Education, Yola"},   	  	
    { title: "Delta State College of Education, Agbor"},   	  	
    { title: "Federal College of Education, Zaria"},   	  	
    { title: "Akwa Ibom State College of Education, Afahansit"},   	  	
    { title: "Kogi State College of Education, Ankpa"},   	  	
    { title: "Adamu Augie College of Education, Argungu"},   	  	
    { title: "Aminu Sale College of Education, Azare"},   	  	
    { title: "Umar Ibn Ibrahim El-Kanemi College of Education, Science and Technology, Bama"},   	  	
    { title: "Nigerian Army School of Education (NASE), Ilorin"},   	  	
    { title: "Zamfara State College of Education, Maru"},   	  	
    { title: "Jigawa State College of Education, Gumel"},   	  	
    { title: "Niger State College of Education, Minna"},   	  	
    { title: "Shehu Shagari College of Education, Sokoto"},   	  	
    { title: "Ansar-Ud-Deen College of Education, Isolo"},   	  	
    { title: "Yewa Central College of Education, Ayetoro, Abeokuta"},   	  	
    { title: "Federal College of Education (T), Umunze"},   	  	
    { title: "Upland College of Education, Badagry"},   	  	
    { title: "College of Education, Arochukwu, Abia"},   	  	
    { title: "College of Education, Ila-Orangun, Osun State"},   	  	
    { title: "Michael Otedola College of Prim. Education, Lagos"},   	  	
    { title: "Kashim Ibrahim College of Education"},   	  	
    { title: "Delta State College of Physical Education, Mosogar"},   	  	
    { title: "Enugu State College of Education (T), Enugu"},   	  	
    { title: "Cross River State College of Education, Akampa"},   	  	
    { title: "Edo State College of Education, Igueben"},   	  	
    { title: "Isaac Jasper Boro College of Education, Sagbama"},   	  	
    { title: "Kogi State College of Education, Kabba"},   	  	
    { title: "Osisa Tech. College of Education"},   	  	
    { title: "St. Augustine College of Education Akoka, Lagos"},   	  	
    { title: "African Thinkers Community of Inquiry College of Education, Enugu"},   	  	
    { title: "Muftau Olanihun College of Education, Ibadan"},   	  	
    { title: "Havard Wilson College of Education, Aba"},   	  	
    { title: "Muhyideen College of Education, Ilorin"},   	  	
    { title: "Corner Stone College of Education, Ikeja"},   	  	
    { title: "Peaceland College of Education, Enugu"},   	  	
    { title: "The College of Education, Nsukka"},   	  	
    { title: "Unity College of Education, Aukpa Adoka, Benue"},   	  	
    { title: "DIAMOND COLLEGE OF EDUCATION, ABA"},   	  	
    { title: "National Teachers Institute(NTI)"},   	  	
    { title: "Kingsey College of Education, Ilorin, Kwara State"},   	  	
    { title: "ECWA College of Education, Jos (ECollege of EducationJ)"},   	  	
    { title: "FESTMED COLLEGE OF EDUCATION, ONDO STATE"},   	  	
    { title: "BETHEL College of Education IJARE, ONDO"},   	  	
    { title: "STEADY FLOW College of Education, IKOM"},   	  	
    { title: "AL HIKMA COLLEGE OF EDUCATION, ANKPA"},   	  	
    { title: "Global College of Education, Bukuru"},   	  	
    { title: "Kwararafa College of Education, Otukpo"},   	  	
    { title: "College of Education, Billiri"},   	  	
    { title: "Lessel College of Education Gboko"},   	  	
    { title: "College of Education, Zing"},   	  	
    { title: "ECWA College of Education Igbaja"},   	  	
    { title: "PAN African College of Education Offa"},   	  	
    { title: "Corona College of Education Lekki"},   	  	
    { title: "College of Education Oju"},   	  	
    { title: "EEI College of Education Otukpo"},   	  	
    { title: "Sinai College of Education & Ent. Studies Gboko, Benue"},   	  	
    { title: "MCF College of Education Agbarha-Otor, Delta"},   	  	
    { title: "Assanusiya College of Education, Odeomu, Osun"},   	  	
    { title: "College of Education, Ero, Akure"},   	  	
    { title: "Calvin Foundation College of Education"},   	  	
    { title: "Best Legacy College of Education Ogbomoso"},   	  	
    { title: "Angel Crown College of Education"},   	  	
    { title: "Elder Oyama Memorial College of Education, Ofat"},   	  	
    { title: "College of Education, Moro, Ife-North"},   	  	
    { title: "Royal City College of Education, Iyesi-Ota"},   	  	
    { title: "ONIT College of Education, Abagana"},   	  	
    { title: "Nana Aishat Memorial College of Education"},   	  	
    { title: "Top-Most College of Education, Ipaja-Agbado"},   	  	
    { title: "Apa College of Education, Aido"},   	  	
    { title: "Awori District College of Education"},   	  	
    { title: "Hill College of Education, Gwanje, Akwanga"},   	  	
    { title: "Aminu Kano College of Education"},   	  	
    { title: "Murtadha College of Education, Olodo"},   	  	
    { title: "Islamic College of Education, Potiskum"},   	  	
    { title: "Royal College of Education, Ikeja, Lagos State"},   	  	
    { title: "Hassan Usman Katsina Polytechnic"},   	  	
    { title: "Plateau State Polytechnic"},  
    { title: "Bayo Tijani College of Education, Lagos"},   	  	 	  	
    { title: "Kano State Polytechnic, Kano"},   	  	 	  	
    { title: "Waziri Umaru Federal Polytechnic"},   	  	 	  	
    { title: "Ramat Polytechnic"},   	  	 	  	
    { title: "Kaduna Polytechnics"},   	  	 	  	
    { title: "Abubakar Tatari Polytechnic"},   	  	 	  	
    { title: "Nuhu Bamalli Polytechnic"},   	  	 	  	
    { title: "Ipere College of Education, Agyaragu"},   	  	 	  	
    { title: "Piaget College of Education"},   	  	 	  	
    { title: "Biga College of Education"},   	  	 	  	
    { title: "Al-Ibadan College of Education"},   	  	 	  	
    { title: "College of Education Kura"},   	  	 	  	
    { title: "Sunnah College of Education"},   	  	 	  	
    { title: "Oswald Waller College of Education Shendam"},   	  	 	  	
    { title: "Imam Saidu College of Education, Funtua"},   	  	 	  	
    { title: "Bilyaminu Othman College of Education Dass"},   	  	 	  	
    { title: "The Polytechnic Iree, Osun State"},   	  	 	  	
    { title: "Bogoro College of Education"},   	  	 	  	
    { title: "Adesina College of Education, Share, Kwara State"},   	  	 	  	
    { title: "Covenant College of Education (CCOE)"},   	  	 	  	
    { title: "JIGAWA STATE COLLEGE OF EDUCATION AND LEGAL STUDIES, RINGIM"},   	  	 	  	
    { title: "CRESTFIELD COLLEGE OF EDUCATION"},   	  	 	  	
    { title: "GRACE COLLEGE OF EDUCATION"},   	  	 	  	
    { title: "AFRICAN CHURCH COLLEGE OF EDUCATION"},   	  	 	  	
    { title: "Kano State College of Education and Remedial Studies, Kano"},   	  	 	  	
    { title: "College of Education, Lanlate, Oyo State"},   	  	 	  	
    { title: "Climax College of Education, Bauchi"},   	  	 	  	
    { title: "College of Education and Legal Studies"},   	  	 	  	
    { title: "Federal College of Education Sokoto"},   	  	 	  	
    { title: "Federal College of Education Bauchi"},   	  	 	  	
    { title: "Federal College of Education (T), ISU Ebonyi State"},   	  	 	  	
    { title: "Annur College of Education Kano	"},   	  	 	  	
    { title: "College of Education Ilemona"},   	  	 	  	
    { title: "Ebenezer College of Education Amangwu"},   	  	 	  	
    { title: "JIBWIS College of Education Gombe"},   	  	 	  	
    { title: "Kogi East College of Education"},   	  	 	  	
    { title: "Gboko College of Education Benue State"},   	  	 	  	
    { title: "Hope and Anchor College of Education"},   	  	 	  	
    { title: "Elizabeth Memorial College of Education Nsukka"},   	  	 	  	
    { title: "Ilori College of Education , Ede"},   	  	 	  	
    { title: "Sarkin Yama Community College of Education"},   	  	 	  	
    { title: "Umar Bun Khatab College of Education, Tudun Nupawa, Kaduna"},   	  	 	  	
    { title: "Peacock College of Education Jalingo"},   	  	 	  	
    { title: "Peace College Of Education Ankpa"},   	  	 	  	
    { title: "Moje College of Education, Erin-Ile"},   	  	 	  	
    { title: "Uli College of Education, Uli"},   	  	 	  	
    { title: "Lifegate College of Education, Asa"},   	  	 	  	
    { title: "JIBWIS College of Education, Jama are"},   	  	 	  	
    { title: "Dala College of Education, Kano"},   	  	 	  	
    { title: "Hamzainab College of Education, Oshogbo"},   	  	 	  	
    { title: "College of Education, Darazo"},   	  	 	  	
    { title: "Abubakar Garba Zagada- Zagada College of Education, Bajoga"},   	  	 	  	
    { title: "Federal College of Education Osun"},   	  	 	  	
    { title: "Federal College of Education Edo"},   	  	 	  	
    { title: "Kashim Ibrahim College of Education"},   	  	 	  	
    { title: "Federal College of Education, Odugbo, Benue State"},   	  	 	  	
    { title: "College of Education Oro"},   	  	 	  	
    { title: "Imo State College of Education, Ihitte/Uboma"},   	  	 	  	
    { title: "Imam Hamzat College of Education, Ilorin"},   	  	 	  	
    { title: "Metro College of Education, Adogi-Lafia"},   	  	 	  	
    { title: "College of Sharia and Arabic Studies"},   	  	 	  	
    { title: "Abdullahi Maikano College of Education, Wase"},   	  	 	  	
    { title: "Nosakhare College of Education, Benin City"},   	  	 	  	
    { title: "Good Shepperd College of Education"},   	  	 	  	
    { title: "Turath College of Education, Kano"},   	  	 	  	
    { title: "Meadow Hall College of Education"},   	  	 	  	
    { title: "Raphat College of Education"},   	  	 	  	
    { title: "Kazaure College of Education"},   	  	 	  	
    { title: "JIBWIS College of Education"},   	  	 	  	
    { title: "Adamu Garkuwa College of Education, Toro"},   	  	 	  	
    { title: "Folrac Fortified College of Education, Ondo"},   	  	 	  	
    { title: "Al-Iman College of Education"},   	  	 	  	
    { title: "Danyaya College of Education, Ningi"},   	  	 	  	
    { title: "Innovative College of Education, Karu"},   	  	 	  	
    { title: "Sam Ale College of Education"},   	  	 	  	
    { title: "Gombe State College of Education, NAFADA"},   	  	 	  	
    { title: "Gand-Plus College of Education"},   	  	 	  	
    { title: "Olekamba College of Education"},   	  	 	  	
    { title: "Emamo College of Education"},   	  	 	  	
    { title: "Aminu Kano College of Islamic and Legal Studies"},   	  	 	  	
    { title: "Muhammad Goni College of Legal and Islamic Studies (MOGOLIS)"},   	  	 	  	
    { title: "A.D. Rufa�i College for Islamic and Legal Studies"},   	  	 	  	
    { title: "Yusuf Bala Usman College of Legal and General Studies, Daura"},   	  	 	  	
    { title: "Bauchi Institute for Arabic and Islamic Studies"},   	  	 	  	
    { title: "Institute of Ecumenical Education (Thinkers Corner)"},   	  	 	  	
    { title: "Ameer Shehu Idris College of Advanced Studies, Zaria"},   	  	 	  	
    { title: "Abuja School of Pension and Retirement Planning, APS Campus"},   	  	 	  	
    { title: "Adecom College of Business Administration and Management Studies, Ibadan"},   	  	 	  	
    { title: "Charkin Maritime Academy, Port Harcourt, Rivers State"},   	  	 	  	
    { title: "College of Administration and Business Studies(CABSP),P.M.B. 1001, Potiskum, Yobe State"},   	  	 	  	
    { title: "College of Business and Management Studies,P. M. B. 003, Konduga, Borno State"},   	  	 	  	
    { title: "College of Management and Business Studies, Abeokuta, Ogun State"},   	  	 	  	
    { title: "Ed-John School of Management, Ikorodu, Lagos"},   	  	 	  	
    { title: "Institute of Administration, ABU. Zaria"},   	  	 	  	
    { title: "Federal Cooperative College,Abuja Road.P.M.B. 2425, Kaduna"},   	  	 	  	
    { title: "Federal Cooperative College,P.M.B. 5033, Eleyele, Ibadan"},   	  	 	  	
    { title: "Federal Cooperative College,Oji  River,P.M.B. 017, Enugu State"},   	  	 	  	
    { title: "Federal School of Social Works, Emene, Enugu"},   	  	 	  	
    { title: "Federal School of Statistics,P.O. Box 20753 U.I. Post Office, Ibadan"},   	  	 	  	
    { title: "Federal School of Statistics,Manchok,P.M.B 2043, Kaduna State"},   	  	 	  	
    { title: "Federal School of Surveying,Ogbomosho Road,P.M.B. 1024, Oyo"},   	  	 	  	
    { title: "Federal Training Centre,17 Marian Road, Calabar"},   	  	 	  	
    { title: "Federal Training Centre,Emene, Industrial LayoutP.M.B. 01483, Enugu"},   	  	 	  	
    { title: "Federal Training Centre,Ikoyi Road, Obalande, P.M.B. 12599, Marina-Lagos"},   	  	 	  	
    { title: "Federal Training Centre,Kulende Housing Estate, Ilorin, Kwara State"},   	  	 	  	
    { title: "Federal Training Centre,70 Isa Kaita Road, P.M.B. 2070, Kaduna"},   	  	 	  	
    { title: "Federal Training Centre,Custom Area, Bama Road, P.M.B 1594, Maiduguri, Borno State"},   	  	 	  	
    { title: "Harvarde College of Management and Business Studies, Abeokuta, Ogun State"},   	  	 	  	
    { title: "Jigawa State Institute of Information Technology, Kazaure"},   	  	 	  	
    { title: "Lapo Institute for Microfinance and Enterprise Development, Uselu, Benin City, Edo"},   	  	 	  	
    { title: "Michael Imodu National Institute for Labour Studies,Ilorin, Kwara State"},   	  	 	  	
    { title: "Metallurgical Training Institute,KM. 6/7 Onitsha-Owerri Road,P.M.B. 1555, Onitsha"},   	  	 	  	
    { title: "National Water Resources Institute,Mando Road, P.M.B. 2309, Kaduna"},   	  	 	  	
    { title: "Nigerian Army School of Finance and Administration,Apapa, Lagos"},   	  	 	  	
    { title: "Nigerian Army School of Signals,H/Q Arakan Barrack, Apapa-Lagos"}, 
    { title: "National Institute for Nigerian Languages (NINLAN)"},   	  	 	  	
    { title: "Nigerian Institute of Journalism,Ikeja, Lagos"},   	  	 	  	
    { title: "Nigerian Institute of Leather and Chemical Technology, Zaria, Kaduna"},   	  	 	  	
    { title: "Nigerian Navy College of Engineering, Sapele, Naval Base, Ogborode, Delta State"},   	  	 	  	
    { title: "Nigerian Navy Finance and Logistics College, Naval Base, Owerrinta, Abia State"},   	  	 	  	
    { title: "National Institute for Legislative and Democratic Studies, Maitama, Abuja"},   	  	 	  	
    { title: "Wavecrest College of Catering and Hospitality Management, Lagos"},   	  	 	  	 	  	 	  	
]

export const schools = [
    {
     label: "All Universities",
     value: "All Universities",
    },
    {
     label: "University of Benin, Benin-city",
     value: "University of Benin, Benin-city",
    },
    {
     label: "Abia State University, Uturu",
     value: "Abia State University, Uturu",
    },
    {
     label: "Adamawa State University, Mubi",
     value: "Adamawa State University, Mubi",
    },
    {
     label: "Akwa-Ibom State University, Ikot Akpaden",
     value: "Akwa-Ibom State University, Ikot Akpaden",
    },
    {
     label: "Bauchi State University, Gadau",
     value: "Bauchi State University, Gadau",
    },
    {
     label: "Benue State University, Makurdi",
     value: "Benue State University, Makurdi",
    },
    {
     label: "Delta State University, Abraka",
     value: "Delta State University, Abraka",
    },
    {
     label: "Ebonyi State University, Abakiliki",
     value: "Ebonyi State University, Abakiliki",
    },
    {
     label: "Rivers State University, Rivers",
     value: "Rivers State University, Rivers",
    },
    {
     label: "Ekiti State University, Ekiti",
     value: "Ekiti State University, Ekiti",
    },
    {
     label: "Gombe State University, Gombe",
     value: "Gombe State University, Gombe",
    },
    {
     label: "Imo State University, Owerri",
     value: "Imo State University, Owerri",
    },
    {
     label: "Yobe State University, Damaturu",
     value: "Yobe State University, Damaturu",
    },
    {
     label: "Lagos State University, Ojo",
     value: "Lagos State University, Ojo",
    },
    {
     label: "Kaduna State University, Kaduna",
     value: "Kaduna State University, Kaduna",
    },
    {
     label: "Nasarawa State University, Keffi",
     value: "Nasarawa State University, Keffi",
    },
    {
     label: "Plateau State University, Bokkos",
     value: "Plateau State University, Bokkos",
    },
    {
     label: "Kwara State University, Illorin",
     value: "Kwara State University, Illorin",
    },
    {
     label: "Taraba State University, Jalingo",
     value: "Taraba State University, Jalingo",
    },
    {
     label: "Osun State University, Osogbo",
     value: "Osun State University, Osogbo",
    },
    {
     label: "Sokoto State University, Sokoto",
     value: "Sokoto State University, Sokoto",
    },
    {
     label: "Kogi State University, Kabba",
     value: "Kogi State University, Kabba",
    },
    {
     label: "Borno State University, Maiduguri",
     value: "Borno State University, Maiduguri",
    },
    {
     label: "Zamfara State University, Zamfara",
     value: "Zamfara State University, Zamfara",
    },
    {
     label: "Edo State University, Uzairue",
     value: "Edo State University, Uzairue",
    },
    {
     label: "Niger Delta University, Yenagoa",
     value: "Niger Delta University, Yenagoa",
    },
    {
     label: "Cross-River State University of Technology, Calabar",
     value: "Cross-River State University of Technology, Calabar",
    },
    {
     label: "Ladoke Akintola University of Technology, Ogbomoso",
     value: "Ladoke Akintola University of Technology, Ogbomoso",
    },
    {
     label: "Kano State University of Science and Technology, Wudil",
     value: "Kano State University of Science and Technology, Wudil",
    },
    {
     label: "Moshood Abiola University of Science and Technology, Abeokuta",
     value: "Moshood Abiola University of Science and Technology, Abeokuta",
    },
    {
     label: "Gombe State University of Science and Technology, Gombe",
     value: "Gombe State University of Science and Technology, Gombe",
    },
    {
     label: "Confluence University of Science and Technology, Osara, kogi",
     value: "Confluence University of Science and Technology, Osara, kogi",
    },
    {
     label: "Ondo State University of Science and Technology, Okitipupa",
     value: "Ondo State University of Science and Technology, Okitipupa",
    },
    {
     label: "Delta State University of Science and Technology, Ozoro",
     value: "Delta State University of Science and Technology, Ozoro",
    },
    {
     label: "Lagos State University of Science and Technology, Ikorodu",
     value: "Lagos State University of Science and Technology, Ikorodu",
    },
    {
     label: "Ondo State University of Medical Sciences, Ondo",
     value: "Ondo State University of Medical Sciences, Ondo",
    },
    {
     label: "Lagos State University of Education, Ijanikin",
     value: "Lagos State University of Education, Ijanikin",
    },
    {
     label: "Bayelsa Medical University, Bayelsa",
     value: "Bayelsa Medical University, Bayelsa",
    },
    {
     label: "Kebbi State University of Science and Technology, Aliero",
     value: "Kebbi State University of Science and Technology, Aliero",
    },
    {
     label: "Oyo State Technical University , Ibadan",
     value: "Oyo State Technical University , Ibadan",
    },
    {
     label: "Enugu State University of Technology, Enugu",
     value: "Enugu State University of Technology, Enugu",
    },
    {
     label: "Adekunle Ajasin University, Akungba",
     value: "Adekunle Ajasin University, Akungba",
    },
    {
     label: "Olabisi Onabanjo University, Ago Iwoye",
     value: "Olabisi Onabanjo University, Ago Iwoye",
    },
    {
     label: "Kingsley Ozumba Mbadiwe University, Ogboko, Imo State",
     value: "Kingsley Ozumba Mbadiwe University, Ogboko, Imo State",
    },
    {
     label: "Ibrahim Badamasi Babangida University, Lapai",
     value: "Ibrahim Badamasi Babangida University, Lapai",
    },
    {
     label: "Yusuf Maitama Sule University, Kano",
     value: "Yusuf Maitama Sule University, Kano",
    },
    {
     label: "Usumanu Danfodiyo University",
     value: "Usumanu Danfodiyo University",
    },
    {
     label: "Prince Abubakar Audu University, Anyigba",
     value: "Prince Abubakar Audu University, Anyigba",
    },
    {
     label: "Ambrose Alli University, Ekpoma",
     value: "Ambrose Alli University, Ekpoma",
    },
    {
     label: "Chukwuemeka Odumegwu Ojukwu University, Uli",
     value: "Chukwuemeka Odumegwu Ojukwu University, Uli",
    },
    {
     label: "Ahmadu Bello University, Zaria",
     value: "Ahmadu Bello University, Zaria",
    },
    {
     label: "Obafemi Awolowo University, Ile-Ife",
     value: "Obafemi Awolowo University, Ile-Ife",
    },
    {
     label: "Nnamdi Azikiwe University, Awka",
     value: "Nnamdi Azikiwe University, Awka",
    },
    {
     label: "Abubakar Tafawa Balewa University, Bauchi",
     value: "Abubakar Tafawa Balewa University, Bauchi",
    },
    {
     label: "Alex Ekueme University, Ndufu-Alike, Ebonyi",
     value: "Alex Ekueme University, Ndufu-Alike, Ebonyi",
    },
    {
     label: "Admiralty University, Ibusa, Delta State",
     value: "Admiralty University, Ibusa, Delta State",
    },
    {
     label: "David Nweze Umahi Federal University of Medical Sciences, Uburu",
     value: "David Nweze Umahi Federal University of Medical Sciences, Uburu",
    },
    {
     label: "Umar Musa Yar' Adua University, Katsina",
     value: "Umar Musa Yar' Adua University, Katsina",
    },
    {
     label: "Bayero University, Kano",
     value: "Bayero University, Kano",
    },
    {
     label: "Federal University Gashau, Yobe",
     value: "Federal University Gashau, Yobe",
    },
    {
     label: "Federal University Dutse, Jigawa",
     value: "Federal University Dutse, Jigawa",
    },
    {
     label: "Federal University Oye-Ekiti, Ekiti",
     value: "Federal University Oye-Ekiti, Ekiti",
    },
    {
     label: "Federal University of Agriculture, Abeokuta",
     value: "Federal University of Agriculture, Abeokuta",
    },
    {
     label: "Federal University of Transportation, Daura, Katsina",
     value: "Federal University of Transportation, Daura, Katsina",
    },
    {
     label: "Federal University of Agriculture, Zuru, Kebbi State",
     value: "Federal University of Agriculture, Zuru, Kebbi State",
    },
    {
     label: "Federal University Birnin-Kebbi, Kebbi",
     value: "Federal University Birnin-Kebbi, Kebbi",
    },
    {
     label: "Federal University Gusau, Zamfara",
     value: "Federal University Gusau, Zamfara",
    },
    {
     label: "Federal University Wukari, Taraba",
     value: "Federal University Wukari, Taraba",
    },
    {
     label: "Federal University Dutsin-Ma, Katsina",
     value: "Federal University Dutsin-Ma, Katsina",
    },
    {
     label: "Federal University Kashere, Gombe",
     value: "Federal University Kashere, Gombe",
    },
    {
     label: "Federal University Lafia, Nasarawa",
     value: "Federal University Lafia, Nasarawa",
    },
    {
     label: "Federal University Lokoja, Kogi",
     value: "Federal University Lokoja, Kogi",
    },
    {
     label: "Federal University Otuoke, Bayelsa",
     value: "Federal University Otuoke, Bayelsa",
    },
    {
     label: "Federal University of Petroleum Resources, Effurun",
     value: "Federal University of Petroleum Resources, Effurun",
    },
    {
     label: "Federal University of Technology, Akure",
     value: "Federal University of Technology, Akure",
    },
    {
     label: "Federal University of Technology, Babura, Jigawa",
     value: "Federal University of Technology, Babura, Jigawa",
    },
    {
     label: "Federal University of Technology, Ikot-Abasi, Akwa-Ibom",
     value: "Federal University of Technology, Ikot-Abasi, Akwa-Ibom",
    },
    {
     label: "Federal University of Health Technology, Otukpo, Benue State",
     value: "Federal University of Health Technology, Otukpo, Benue State",
    },
    {
     label: "Federal University of Health Sciences, Ila Orangun, Osun State",
     value: "Federal University of Health Sciences, Ila Orangun, Osun State",
    },
    {
     label: "Federal University of Technology, Minna",
     value: "Federal University of Technology, Minna",
    },
    {
     label: "Federal University of Technology, Owerri",
     value: "Federal University of Technology, Owerri",
    },
    {
     label: "Dennis Osadebe University, Asaba",
     value: "Dennis Osadebe University, Asaba",
    },
    {
     label: "Bamidele Olumilua University of Science and Technology, Ikere, Ekiti State",
     value: "Bamidele Olumilua University of Science and Technology, Ikere, Ekiti State",
    },
    {
     label: "Modibbo Adama University of Technology, Yola",
     value: "Modibbo Adama University of Technology, Yola",
    },
    {
     label: "Michael Okpara University of Agriculture, Umudike",
     value: "Michael Okpara University of Agriculture, Umudike",
    },
    {
     label: "National Open University of Nigeria, Lagos",
     value: "National Open University of Nigeria, Lagos",
    },
    {
     label: "Ignatius Ajuru University of Education, Rumuolumeni",
     value: "Ignatius Ajuru University of Education, Rumuolumeni",
    },
    {
     label: "Tai Solarin University of Education, Ijebu-Ode",
     value: "Tai Solarin University of Education, Ijebu-Ode",
    },
    {
     label: "Sa'adatu Rimi University of Education",
     value: "Sa'adatu Rimi University of Education",
    },
    {
     label: "Emmanuel Alayande University of Education, Oyo, Oyo State",
     value: "Emmanuel Alayande University of Education, Oyo, Oyo State",
    },
    {
     label: "Niger State University of Education, Minna",
     value: "Niger State University of Education, Minna",
    },
    {
     label: "Shehu Shagari University of Education, Sokoto",
     value: "Shehu Shagari University of Education, Sokoto",
    },
    {
     label: "Sule Lamido University, Kafin Hausa, Jigawa",
     value: "Sule Lamido University, Kafin Hausa, Jigawa",
    },
    {
     label: "University of Africa. Toru Orua Bayelsa State",
     value: "University of Africa. Toru Orua Bayelsa State",
    },
    {
     label: "Enugu State University of Medical and Applied Sciences. Igbo-Eno",
     value: "Enugu State University of Medical and Applied Sciences. Igbo-Eno",
    },
    {
     label: "University of Delta. Agbor",
     value: "University of Delta. Agbor",
    },
    {
     label: "University of Illesa. Osun State",
     value: "University of Illesa. Osun State",
    },
    {
     label: "University of Calabar",
     value: "University of Calabar",
    },
    {
     label: "University of Ibadan",
     value: "University of Ibadan",
    },
    {
     label: "University of Illorin",
     value: "University of Illorin",
    },
    {
     label: "University of Jos",
     value: "University of Jos",
    },
    {
     label: "University of Lagos",
     value: "University of Lagos",
    },
    {
     label: "University of Maiduguri",
     value: "University of Maiduguri",
    },
    {
     label: "University of Nigeria, Nsukka",
     value: "University of Nigeria, Nsukka",
    },
    {
     label: "University of Port-Harcourt",
     value: "University of Port-Harcourt",
    },
    {
     label: "University of Uyo",
     value: "University of Uyo",
    },
    {
     label: "University of Agriculture, Makurdi",
     value: "University of Agriculture, Makurdi",
    },
    {
     label: "Nigeria Police Academy. Wudil",
     value: "Nigeria Police Academy. Wudil",
    },
    {
     label: "Nigeria Defence Academy. Kaduna",
     value: "Nigeria Defence Academy. Kaduna",
    },
    {
     label: "University of Abuja. Gwagwalada",
     value: "University of Abuja. Gwagwalada",
    },
    {
     label: "Nigerian Maritime University, Okerenkoko, Delta State",
     value: "Nigerian Maritime University, Okerenkoko, Delta State",
    },
    {
     label: "Nigerian Army University, BIU",
     value: "Nigerian Army University, BIU",
    },
    {
     label: "Airforce Institute of Technology. Kaduna",
     value: "Airforce Institute of Technology. Kaduna",
    },
    {
     label: "Achievers University, Owo",
     value: "Achievers University, Owo",
    },
    {
     label: "Adeleke University, Ede",
     value: "Adeleke University, Ede",
    },
    {
     label: "Afe Babalola University, Ado-Ekiti, Ekiti State",
     value: "Afe Babalola University, Ado-Ekiti, Ekiti State",
    },
    {
     label: "African University of Science & Technology, Abuja",
     value: "African University of Science & Technology, Abuja",
    },
    {
     label: "Ajayi Crowther University, Ibadan",
     value: "Ajayi Crowther University, Ibadan",
    },
    {
     label: "Al-Hikmah University, Ilorin",
     value: "Al-Hikmah University, Ilorin",
    },
    {
     label: "Al-Qalam University, Katsina",
     value: "Al-Qalam University, Katsina",
    },
    {
     label: "American University of Nigeria, Yola",
     value: "American University of Nigeria, Yola",
    },
    {
     label: "Augustine University",
     value: "Augustine University",
    },
    {
     label: "Babcock University,Ilishan-Remo",
     value: "Babcock University,Ilishan-Remo",
    },
    {
     label: "Baze University",
     value: "Baze University",
    },
    {
     label: "Bells University of Technology, Otta",
     value: "Bells University of Technology, Otta",
    },
    {
     label: "Benson Idahosa University, Benin City",
     value: "Benson Idahosa University, Benin City",
    },
    {
     label: "Bingham University, New Karu",
     value: "Bingham University, New Karu",
    },
    {
     label: "Bowen University, Iwo",
     value: "Bowen University, Iwo",
    },
    {
     label: "Caleb University, Lagos",
     value: "Caleb University, Lagos",
    },
    {
     label: "Caritas University, Enugu",
     value: "Caritas University, Enugu",
    },
    {
     label: "Covenant University Ota",
     value: "Covenant University Ota",
    },
    {
     label: "Crawford University Igbesa",
     value: "Crawford University Igbesa",
    },
    {
     label: "Crescent University",
     value: "Crescent University",
    },
    {
     label: "Edwin Clark University, Kaigbodo",
     value: "Edwin Clark University, Kaigbodo",
    },
    {
     label: "Elizade University, Ilara-Mokin",
     value: "Elizade University, Ilara-Mokin",
    },
    {
     label: "Evangel University, Akaeze",
     value: "Evangel University, Akaeze",
    },
    {
     label: "Fountain Unveristy, Oshogbo",
     value: "Fountain Unveristy, Oshogbo",
    },
    {
     label: "Godfrey Okoye University, Ugwuomu-Nike – Enugu State",
     value: "Godfrey Okoye University, Ugwuomu-Nike – Enugu State",
    },
    {
     label: "Gregory University, Uturu",
     value: "Gregory University, Uturu",
    },
    {
     label: "Joseph Ayo Babalola University, Ikeji-Arakeji",
     value: "Joseph Ayo Babalola University, Ikeji-Arakeji",
    },
    {
     label: "Hallmark University, Ijebi Itele, Ogun",
     value: "Hallmark University, Ijebi Itele, Ogun",
    },
    {
     label: "Hezekiah University, Umudi",
     value: "Hezekiah University, Umudi",
    },
    {
     label: "Igbinedion University Okada",
     value: "Igbinedion University Okada",
    },
    {
     label: "Kings University, Ode Omu",
     value: "Kings University, Ode Omu",
    },
    {
     label: "Kwararafa University, Wukari",
     value: "Kwararafa University, Wukari",
    },
    {
     label: "Landmark University, Omu-Aran",
     value: "Landmark University, Omu-Aran",
    },
    {
     label: "Lead City University, Ibadan",
     value: "Lead City University, Ibadan",
    },
    {
     label: "Madonna University, Okija",
     value: "Madonna University, Okija",
    },
    {
     label: "Mcpherson University, Seriki Sotayo, Ajebo",
     value: "Mcpherson University, Seriki Sotayo, Ajebo",
    },
    {
     label: "Micheal & Cecilia Ibru University",
     value: "Micheal & Cecilia Ibru University",
    },
    {
     label: "Mountain Top University",
     value: "Mountain Top University",
    },
    {
     label: "Nile University of Nigeria, Abuja",
     value: "Nile University of Nigeria, Abuja",
    },
    {
     label: "Novena University, Ogume",
     value: "Novena University, Ogume",
    },
    {
     label: "Obong University, Obong Ntak",
     value: "Obong University, Obong Ntak",
    },
    {
     label: "Oduduwa University, Ipetumodu, Osun State",
     value: "Oduduwa University, Ipetumodu, Osun State",
    },
    {
     label: "Pan-Atlantic University, Lagos",
     value: "Pan-Atlantic University, Lagos",
    },
    {
     label: "Paul University, Awka, Anambra State",
     value: "Paul University, Awka, Anambra State",
    },
    {
     label: "Redeemers University, Ede",
     value: "Redeemers University, Ede",
    },
    {
     label: "Renaissance University, Enugu",
     value: "Renaissance University, Enugu",
    },
    {
     label: "Rhema University, Obeama-Asa, Rivers State",
     value: "Rhema University, Obeama-Asa, Rivers State",
    },
    {
     label: "Ritman University, Ikot Ekpene, Akwa Ibom",
     value: "Ritman University, Ikot Ekpene, Akwa Ibom",
    },
    {
     label: "Salem University, Lokoja",
     value: "Salem University, Lokoja",
    },
    {
     label: "Samuel Adegboyega University, Ogwa",
     value: "Samuel Adegboyega University, Ogwa",
    },
    {
     label: "Southwestern University, Oku Owa",
     value: "Southwestern University, Oku Owa",
    },
    {
     label: "Summit University, Offa",
     value: "Summit University, Offa",
    },
    {
     label: "Tansian University, Umunya",
     value: "Tansian University, Umunya",
    },
    {
     label: "University of Mkar, Mkar",
     value: "University of Mkar, Mkar",
    },
    {
     label: "Veritas University, Abuja",
     value: "Veritas University, Abuja",
    },
    {
     label: "Wellspring University, Evbuobanosa, Edo State",
     value: "Wellspring University, Evbuobanosa, Edo State",
    },
    {
     label: "Wesley University Ondo",
     value: "Wesley University Ondo",
    },
    {
     label: "Western Delta University, Oghara Delta State",
     value: "Western Delta University, Oghara Delta State",
    },
    {
     label: "Christopher University Mowe",
     value: "Christopher University Mowe",
    },
    {
     label: "Kola Daisi University Ibadan, Oyo State",
     value: "Kola Daisi University Ibadan, Oyo State",
    },
    {
     label: "Anchor University Ayobo Lagos State",
     value: "Anchor University Ayobo Lagos State",
    },
    {
     label: "Dominican University Ibadan Oyo State",
     value: "Dominican University Ibadan Oyo State",
    },
    {
     label: "Dominion University Ibadan, Oyo State",
     value: "Dominion University Ibadan, Oyo State",
    },
    {
     label: "Trinity University Ogun State",
     value: "Trinity University Ogun State",
    },
    {
     label: "Huda University, Gusau, Zamafara State",
     value: "Huda University, Gusau, Zamafara State",
    },
    {
     label: "Westland University Iwo, Osun State",
     value: "Westland University Iwo, Osun State",
    },
    {
     label: "Greenfield University, Kaduna",
     value: "Greenfield University, Kaduna",
    },
    {
     label: "Skyline University, Kano",
     value: "Skyline University, Kano",
    },
    {
     label: "Eko University of Medical and Health Sciences Ijanikin, Lagos",
     value: "Eko University of Medical and Health Sciences Ijanikin, Lagos",
    },
    {
     label: "Atiba University Oyo",
     value: "Atiba University Oyo",
    },
    {
     label: "PAMO University of Medical Sciences, Portharcourt",
     value: "PAMO University of Medical Sciences, Portharcourt",
    },
    {
     label: "Precious Cornerstone University, Oyo",
     value: "Precious Cornerstone University, Oyo",
    },
    {
     label: "Spiritan University, Nneochi Abia State",
     value: "Spiritan University, Nneochi Abia State",
    },
    {
     label: "Clifford University Owerrinta Abia State",
     value: "Clifford University Owerrinta Abia State",
    },
    {
     label: "Coal City University Enugu State",
     value: "Coal City University Enugu State",
    },
    {
     label: "Crown Hill University Eiyenkorin, Kwara State",
     value: "Crown Hill University Eiyenkorin, Kwara State",
    },
    {
     label: "Arthur Javis University Akpoyubo Cross river State",
     value: "Arthur Javis University Akpoyubo Cross river State",
    },
    {
     label: "Legacy University, Okija Anambra State",
     value: "Legacy University, Okija Anambra State",
    },
    {
     label: "Topfaith University, Mkpatak, Akwa Ibom State",   	
     value: "Topfaith University, Mkpatak, Akwa Ibom State",   	
    },
    {
     label: "Thomas Adewumi University, Oko-Irese, Kwara State",   	
     value: "Thomas Adewumi University, Oko-Irese, Kwara State",   	
    },
    {
     label: "Maranathan University, Mgbidi, Imo State",   	
     value: "Maranathan University, Mgbidi, Imo State",   	
    },
    {
     label: "Ave Maria University, Piyanko, Nasarawa State",   	
     value: "Ave Maria University, Piyanko, Nasarawa State",   	
    },
    {
     label: "Al-Istiqama University, Sumaila, Kano State",   	
     value: "Al-Istiqama University, Sumaila, Kano State",   	
    },
    {
     label: "Mudiame University, Irrua, Edo State",   	
     value: "Mudiame University, Irrua, Edo State",   	
    },
    {
     label: "Havilla University, Nde-Ikom, Cross River State",   	
     value: "Havilla University, Nde-Ikom, Cross River State",   	
    },
    {
     label: "Claretian University of Nigeria, Nekede, Imo State",   	
     value: "Claretian University of Nigeria, Nekede, Imo State",   	
    },
    {
     label: "NOK University, Kachia, Kaduna State",   	
     value: "NOK University, Kachia, Kaduna State",   	
    },
    {
     label: "Karl-Kumm University, Vom, Plateau State",   	
     value: "Karl-Kumm University, Vom, Plateau State",   	
    },
    {
     label: "James Hope University, Lagos, Lagos State",   	
     value: "James Hope University, Lagos, Lagos State",   	
    },
    {
     label: "Maryam Abacha American University of Nigeria, Kano State",   	
     value: "Maryam Abacha American University of Nigeria, Kano State",   	
    },
    {
     label: "Capital City University, Kano State",   	
     value: "Capital City University, Kano State",   	
    },
    {
     label: "Ahman Pategi University, Kwara State",   	
     value: "Ahman Pategi University, Kwara State",   	
    },
    {
     label: "University of Offa, Kwara State",   	
     value: "University of Offa, Kwara State",   	
    },
    {
     label: "Mewar International University, Masaka, Nasarawa State",   	
     value: "Mewar International University, Masaka, Nasarawa State",   	
    },
    {
     label: "Edusoko University, Bida, Niger State",   	
     value: "Edusoko University, Bida, Niger State",   	
    },
    {
     label: "Philomath University, Kuje, Abuja",   	
     value: "Philomath University, Kuje, Abuja",   	
    },
    {
     label: "Khadija University, Majia, Jigawa State",   	
     value: "Khadija University, Majia, Jigawa State",   	
    },
    {
     label: "Anan University, Kwall, Plateau State",   	
     value: "Anan University, Kwall, Plateau State",   	
    },
    {
     label: "PEN Resource University, Gombe",   	
     value: "PEN Resource University, Gombe",   	
    },
    {
     label: "Al-Ansar University, Maiduguri, Borno",   	
     value: "Al-Ansar University, Maiduguri, Borno",   	
    },
    {
     label: "Margaret Lawrence University, Umunede, Delta State",   	
     value: "Margaret Lawrence University, Umunede, Delta State",   	
    },
    {
     label: "Khalifa Isiyaku Rabiu University, Kano",   	
     value: "Khalifa Isiyaku Rabiu University, Kano",   	
    },
    {
     label: "Sports University, Idumuje, Ugboko, Delta State",   	
     value: "Sports University, Idumuje, Ugboko, Delta State",   	
    },
    {
     label: "Baba Ahmed University, Kano State",   	
     value: "Baba Ahmed University, Kano State",   	
    },
    {
     label: "Saisa University of Medical Sciences and Technology, Sokoto State",   	
     value: "Saisa University of Medical Sciences and Technology, Sokoto State",   	
    },
    {
     label: "Nigerian British University, Asa, Abia State",   	
     value: "Nigerian British University, Asa, Abia State",   	
    },
    {
     label: "Peter University, Achina-Onneh Anambra State",   	
     value: "Peter University, Achina-Onneh Anambra State",   	
    },
    {
     label: "Newgate University, MInna, Niger State",   	
     value: "Newgate University, MInna, Niger State",   	
    },
    {
     label: "European University of Nigeria, Duboyi, FCT",   	
     value: "European University of Nigeria, Duboyi, FCT",   	
    },
    {
     label: "NorthWest University Sokoto State",   	
     value: "NorthWest University Sokoto State",   	
    },
    {
     label: "Rayhaan University, Kebbi",   	
     value: "Rayhaan University, Kebbi",   	
    },
    {
     label: "Muhammad Kamalud University Kwara",   	
     value: "Muhammad Kamalud University Kwara",   	
    },
    {
     label: "Sam Maris University, Ondo",   	
     value: "Sam Maris University, Ondo",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Augustine University",   	
     value: "Augustine University",   	
    },
    {
     label: "Aletheia University, Ago-Iwoye Ogun State",   	
     value: "Aletheia University, Ago-Iwoye Ogun State",   	
    },
    {
     label: "Lux Mundi University Umuahia, Abia State",   	
     value: "Lux Mundi University Umuahia, Abia State",   	
    },
    {
     label: "Maduka University, Ekwegbe, Enugu State",   	
     value: "Maduka University, Ekwegbe, Enugu State",   	
    },
    {
     label: "PeaceLand University, Enugu State",   	
     value: "PeaceLand University, Enugu State",   	
    },
    {
     label: "Amadeus University, Amizi, Abia State",   	
     value: "Amadeus University, Amizi, Abia State",   	
    },
    {
     label: "Vision University, Ikogbo, Ogun State",   	
     value: "Vision University, Ikogbo, Ogun State",   	
    },
    {
     label: "Azman University, Kano State",   	
     value: "Azman University, Kano State",   	
    },
    {
     label: "Franco British International University, Kaduna State",   	
     value: "Franco British International University, Kaduna State",   	
    },
    {
     label: "Gerar University of Medical Science Imope Ijebu, Ogun State",   	
     value: "Gerar University of Medical Science Imope Ijebu, Ogun State",   	
    },
    {
     label: "Canadian University of Nigeria, Abuja",   	
     value: "Canadian University of Nigeria, Abuja",   	
    },
    {
     label: "British Canadian University, Obufu Cross River State",   	
     value: "British Canadian University, Obufu Cross River State",   	
    },
    {
     label: "Hensard University, Toru-Orua, Sagbama, Bayelsa State",   	
     value: "Hensard University, Toru-Orua, Sagbama, Bayelsa State",   	
    },
    {
     label: "Amaj University, Kwali, Abuja",   	
     value: "Amaj University, Kwali, Abuja",   	
    },
    {
     label: "Phoenix University, Agwada, Nasarawa State",   	
     value: "Phoenix University, Agwada, Nasarawa State",   	
    },
    {
     label: "Wigwe University, Isiokpo Rivers State",   	
     value: "Wigwe University, Isiokpo Rivers State",   	
    },
    {
     label: "Hillside University of Science and Technology, Okemisi, Ekiti State",   	
     value: "Hillside University of Science and Technology, Okemisi, Ekiti State",   	
    },
    {
     label: "University on the Niger, Umunya, Anambra state",   	
     value: "University on the Niger, Umunya, Anambra state",   	
    },
    {
     label: "Elrazi Medical University Yargaya University, Kano State",   	
     value: "Elrazi Medical University Yargaya University, Kano State",   	
    },
    {
     label: "Venite University, Iloro-Ekiti, Ekiti State",   	
     value: "Venite University, Iloro-Ekiti, Ekiti State",   	
    },
    {
     label: "Shanahan University Onitsha, Anambra State",   	
     value: "Shanahan University Onitsha, Anambra State",   	
    },
    {
     label: "The Duke Medical University, Calabar, Cross River State",   	
     value: "The Duke Medical University, Calabar, Cross River State",   	
    },
    {
     label: "Mercy Medical University, Iwo, Ogun State",   	
     value: "Mercy Medical University, Iwo, Ogun State",   	
    },
    {
     label: "Cosmopolitan University Abuja",   	
     value: "Cosmopolitan University Abuja",   	
    },
    {
     label: "Miva Open University, Abuja FCT",   	
     value: "Miva Open University, Abuja FCT",   	
    },
    {
     label: "Iconic Open University, Sokoto State",   	
     value: "Iconic Open University, Sokoto State",   	
    },
    {
     label: "West Midlands Open University, Ibadan, Oyo State",   	  	
     value: "West Midlands Open University, Ibadan, Oyo State",   	  	
    },
    {
     label: "Nigerian University of Technology and Management, Apapa, Lagos State",   	
     value: "Nigerian University of Technology and Management, Apapa, Lagos State",   	
    },
    {
     label: "Prime University, Kuje, FCT Abuja",   	
     value: "Prime University, Kuje, FCT Abuja",   	
    },
    {
     label: "Jewel University, Gombe state",   	
     value: "Jewel University, Gombe state",   	
    },
    {
     label: "College of Petroleum and Energy Studies, Kaduna State",   	
     value: "College of Petroleum and Energy Studies, Kaduna State",   	
    },
    {
     label: "El-Amin University, Minna, Niger State",   	
     value: "El-Amin University, Minna, Niger State",   	
    },
    {
     label: "Al-Muhibbah Open University, Abuja",   	
     value: "Al-Muhibbah Open University, Abuja",   	
    },
    {
     label: "Al-Bayan University, Ankpa, Kogi State",   	
     value: "Al-Bayan University, Ankpa, Kogi State",   	
    },
    {
     label: "Akanu Ibiam Federal Polytechnic Unwana",   	
     value: "Akanu Ibiam Federal Polytechnic Unwana",   	
    },
    {
     label: "Auchi Polytechnic, Auchi, Edo State",   	
     value: "Auchi Polytechnic, Auchi, Edo State",   	
    },
    {
     label: "Federal Polytechnic Ado Ekiti, Ekiti State",   	
     value: "Federal Polytechnic Ado Ekiti, Ekiti State",   	
    },
    {
     label: "Federal Polytechnic Bali, Taraba State",   	
     value: "Federal Polytechnic Bali, Taraba State",   	
    },
    {
     label: "Federal Polytechnic Bauchi, P.M. B 231, Bauchi State",   	
     value: "Federal Polytechnic Bauchi, P.M. B 231, Bauchi State",   	
    },
    {
     label: "Federal Polytechnic Bida, P.M.B 55, Niger State",   	
     value: "Federal Polytechnic Bida, P.M.B 55, Niger State",   	
    },
    {
     label: "Federal Polytechnic Damaturu, P.M.B 1006, Yobe State",   	
     value: "Federal Polytechnic Damaturu, P.M.B 1006, Yobe State",   	
    },
    {
     label: "Federal Polytechnic, Daura, Katsina State",   	
     value: "Federal Polytechnic, Daura, Katsina State",   	
    },
    {
     label: "Federal Polytechnic Ede, Osun State",   	
     value: "Federal Polytechnic Ede, Osun State",   	
    },
    {
     label: "Federal Polytechnic Ekowe, Bayelsa State",   	
     value: "Federal Polytechnic Ekowe, Bayelsa State",   	
    },
    {
     label: "Federal Polytechnic Idah P.M.B 1035, Kogi State",   	
     value: "Federal Polytechnic Idah P.M.B 1035, Kogi State",   	
    },
    {
     label: "Federal Polytechnic Ilaro, P.M.B 50, Ogun State",   	
     value: "Federal Polytechnic Ilaro, P.M.B 50, Ogun State",   	
    },
    {
     label: "Federal Polytechnic Ile-Oluji, Ondo State",   	
     value: "Federal Polytechnic Ile-Oluji, Ondo State",   	
    },
    {
     label: "Federal Polytechnic Kaura Namoda, P.M.B, 1012, Zamfara State",   	
     value: "Federal Polytechnic Kaura Namoda, P.M.B, 1012, Zamfara State",   	
    },
    {
     label: "Federal Polytechnic Kaltungo, Gombe State",   	
     value: "Federal Polytechnic Kaltungo, Gombe State",   	
    },
    {
     label: "Federal Polytechnic Mubi, PMB 35 Mubi, Adamawa State",   	
     value: "Federal Polytechnic Mubi, PMB 35 Mubi, Adamawa State",   	
    },
    {
     label: "Federal Polytechnic Nasarawa, P.M.B. 01 Nasarawa State",   	
     value: "Federal Polytechnic Nasarawa, P.M.B. 01 Nasarawa State",   	
    },
    {
     label: "Federal Polytechnic Nekede, P.M.B 1036, Owerri, Imo State",   	
     value: "Federal Polytechnic Nekede, P.M.B 1036, Owerri, Imo State",   	
    },
    {
     label: "Federal Polytechnic Offa, P.M.B 420, Kwara State",   	
     value: "Federal Polytechnic Offa, P.M.B 420, Kwara State",   	
    },
    {
     label: "Federal Polytechnic Oko, Anambra State",   	
     value: "Federal Polytechnic Oko, Anambra State",   	
    },
    {
     label: "Federal Polytechnic of Oil and Gas Bonny, Rivers State",   	
     value: "Federal Polytechnic of Oil and Gas Bonny, Rivers State",   	
    },
    {
     label: "Federal Polytechnic Ukana,P.M.B 2014Akwa Ibom State",   	
     value: "Federal Polytechnic Ukana,P.M.B 2014Akwa Ibom State",   	
    },
    {
     label: "Hussaini Adamu Federal Polytechnic, P.M.B 5004, Kazaure Jigawa State",   	
     value: "Hussaini Adamu Federal Polytechnic, P.M.B 5004, Kazaure Jigawa State",   	
    },
    {
     label: "Kaduna Polytechnic, Kaduna",   	
     value: "Kaduna Polytechnic, Kaduna",   	
    },
    {
     label: "National Institute of Construction Technology Uromi",   	
     value: "National Institute of Construction Technology Uromi",   	
    },
    {
     label: "Waziri Umaru Federal Polytechnic, P.M.B. 1034, Birnin Kebbi",   	
     value: "Waziri Umaru Federal Polytechnic, P.M.B. 1034, Birnin Kebbi",   	
    },
    {
     label: "Yaba College of Technology, P.M.B. 2011, Yaba, Lagos State",   	
     value: "Yaba College of Technology, P.M.B. 2011, Yaba, Lagos State",   	
    },
    {
     label: "Federal Polytechnic Ayede, Oyo State",   	
     value: "Federal Polytechnic Ayede, Oyo State",   	
    },
    {
     label: "Federal Polytechnic Munguno, Borno State",   	
     value: "Federal Polytechnic Munguno, Borno State",   	
    },
    {
     label: "Federal Polytechnic Nyak, Shendam, Plateau State",   	
     value: "Federal Polytechnic Nyak, Shendam, Plateau State",   	
    },
    {
     label: "Federal Polytechnic Ohodo, Enugu State",   	
     value: "Federal Polytechnic Ohodo, Enugu State",   	
    },
    {
     label: "Federal Polytechnic Ugep, Cross Rivers State",   	
     value: "Federal Polytechnic Ugep, Cross Rivers State",   	
    },
    {
     label: "Federal Polytechnic Wannune, Benue State",   	
     value: "Federal Polytechnic Wannune, Benue State",   	
    },
    {
     label: "Airforce Institute of Technology (AFIT),NAF Base Kaduna",   	
     value: "Airforce Institute of Technology (AFIT),NAF Base Kaduna",   	
    },
    {
     label: "Petroleum Training Institute Effurun. Delta State",   	
     value: "Petroleum Training Institute Effurun. Delta State",   	
    },
    {
     label: "Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi",   	
     value: "Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi",   	
    },
    {
     label: "Nigerian College of Aviation Technology (NCAT), Sokoto Road, Zaria, Kaduna",   	
     value: "Nigerian College of Aviation Technology (NCAT), Sokoto Road, Zaria, Kaduna",   	
    },
    {
     label: "Abdu Gusau Polytechnic, Talata Mafara P.M.B. 1021, Talata Mafara, Zamfara State",   	
     value: "Abdu Gusau Polytechnic, Talata Mafara P.M.B. 1021, Talata Mafara, Zamfara State",   	
    },
    {
     label: "Abia State Polytechnic, Aba,P.M.B. 7166, Aba, Abia State",   	
     value: "Abia State Polytechnic, Aba,P.M.B. 7166, Aba, Abia State",   	
    },
    {
     label: "Abraham Adesanya Polytechnic, Dogbolu/Akanran Ibadan Road, Atikori, Ijebu Igbo",   	
     value: "Abraham Adesanya Polytechnic, Dogbolu/Akanran Ibadan Road, Atikori, Ijebu Igbo",   	
    },
    {
     label: "Abubakar Tatari Ali Polytechnic, Bauchi,Jos Rd, Bauchi, Bauchi State",   	
     value: "Abubakar Tatari Ali Polytechnic, Bauchi,Jos Rd, Bauchi, Bauchi State",   	
    },
    {
     label: "Adamawa State Polytechnic, Yola,P.M.B. 2146, Yola,Adamawa State",   	
     value: "Adamawa State Polytechnic, Yola,P.M.B. 2146, Yola,Adamawa State",   	
    },
    {
     label: "Adeseun Ogundoyin Polytechnic, Eruwa(Formerly Ibarapa Polytechnic Eruwa), Oyo State",   	
     value: "Adeseun Ogundoyin Polytechnic, Eruwa(Formerly Ibarapa Polytechnic Eruwa), Oyo State",   	
    },
    {
     label: "Akwa Ibom State College of Art & Science, Nung Ukim,P.M.B. 1044, Akwa Ibom State",   	
     value: "Akwa Ibom State College of Art & Science, Nung Ukim,P.M.B. 1044, Akwa Ibom State",   	
    },
    {
     label: "Akwa Ibom State Polytechnic,Ikot Osurua, Ikot -Ekpene,Akwa Ibom State",   	
     value: "Akwa Ibom State Polytechnic,Ikot Osurua, Ikot -Ekpene,Akwa Ibom State",   	
    },
    {
     label: "Anambra State Polytechnic, PMB 002, Mgbakwu,  Anambra State",   	
     value: "Anambra State Polytechnic, PMB 002, Mgbakwu,  Anambra State",   	
    },
    {
     label: "Bayelsa State Polytechnic, Aleibiri, P.M.B 168 Ekeremor",   	
     value: "Bayelsa State Polytechnic, Aleibiri, P.M.B 168 Ekeremor",   	
    },
    {
     label: "Benue State Polytechnic, Ugbokolo,P.M.B. 01 Ugbokolo, Benue State",   	
     value: "Benue State Polytechnic, Ugbokolo,P.M.B. 01 Ugbokolo, Benue State",   	
    },
    {
     label: "Binyaminu Usman Polytechnic, Hadejia,Jigawa State",   	
     value: "Binyaminu Usman Polytechnic, Hadejia,Jigawa State",   	
    },
    {
     label: "Cross River State Institute of Technology And Management (CRITM) Km 95, Ikom-Calabar Highway",   	
     value: "Cross River State Institute of Technology And Management (CRITM) Km 95, Ikom-Calabar Highway",   	
    },
    {
     label: "D.S. Adegbenro ICT Polytechnic, Itori-Ewekoro,P.M.B. 5026, Ifo, Ogun State",   	
     value: "D.S. Adegbenro ICT Polytechnic, Itori-Ewekoro,P.M.B. 5026, Ifo, Ogun State",   	
    },
    {
     label: "Delta State Polytechnic, Ogwashi-Uku,P.M.B. 1030, Ogwashi-Uku,Delta State",   	
     value: "Delta State Polytechnic, Ogwashi-Uku,P.M.B. 1030, Ogwashi-Uku,Delta State",   	
    },
    {
     label: "Delta State Polytechnic, Otefe-Oghara, P.M.B. 03, Otefe-Oghara, Delta State",   	
     value: "Delta State Polytechnic, Otefe-Oghara, P.M.B. 03, Otefe-Oghara, Delta State",   	
    },
    {
     label: "Delta State Polytechnic, Ozoro,P.M.B. 05, Ozoro, Delta State",   	
     value: "Delta State Polytechnic, Ozoro,P.M.B. 05, Ozoro, Delta State",   	
    },
    {
     label: "Delta State School of Marine Technology, Burutu,P.M.B. 1060, Warri, Delta",   	
     value: "Delta State School of Marine Technology, Burutu,P.M.B. 1060, Warri, Delta",   	
    },
    {
     label: "Edo State Polytechnic, Usen.P.M.B. 1104, Benin City, Edo State",   	
     value: "Edo State Polytechnic, Usen.P.M.B. 1104, Benin City, Edo State",   	
    },
    {
     label: "Ekiti State College of Agriculture and Technology, Isan Ekiti, Ekiti State",   	
     value: "Ekiti State College of Agriculture and Technology, Isan Ekiti, Ekiti State",   	
    },
    {
     label: "Enugu State Polytechnic, Iwollo",   	
     value: "Enugu State Polytechnic, Iwollo",   	
    },
    {
     label: "Hassan Usman Katsina Polytechnic (HUK),P.M.B. 2052, Katsina, Nigeria",   	
     value: "Hassan Usman Katsina Polytechnic (HUK),P.M.B. 2052, Katsina, Nigeria",   	
    },
    {
     label: "Gateway Polytechnic, SaapadeProf. Wole Soyinka Way, Ogun State",   	
     value: "Gateway Polytechnic, SaapadeProf. Wole Soyinka Way, Ogun State",   	
    },
    {
     label: "Imo State Polytechnic, Umuagwo, Ohaji, P.M.B. 1472, Umuagwo, Ohaji Owerri,Imo State",   	
     value: "Imo State Polytechnic, Umuagwo, Ohaji, P.M.B. 1472, Umuagwo, Ohaji Owerri,Imo State",   	
    },
    {
     label: "Institute of Management and Technology, Enugu,P.M.B 01079, Enugu, Enugu State",   	
     value: "Institute of Management and Technology, Enugu,P.M.B 01079, Enugu, Enugu State",   	
    },
    {
     label: "Isa Mustapha Agwai Polytechnic Lafia",   	
     value: "Isa Mustapha Agwai Polytechnic Lafia",   	
    },
    {
     label: "Jigawa State Polytechnic, Dutse, Jigawa State",   	
     value: "Jigawa State Polytechnic, Dutse, Jigawa State",   	
    },
    {
     label: "Kano State Polytechnic, Kano",   	
     value: "Kano State Polytechnic, Kano",   	
    },
    {
     label: "Ken Sarowiwa Polytechnic, Bori, Rivers State",   	
     value: "Ken Sarowiwa Polytechnic, Bori, Rivers State",   	
    },
    {
     label: "Kogi State Polytechnic, Lokoja, Kogi State",   	
     value: "Kogi State Polytechnic, Lokoja, Kogi State",   	
    },
    {
     label: "Kwara State Polytechnic, Ilorin, Kwara State",   	
     value: "Kwara State Polytechnic, Ilorin, Kwara State",   	
    },
    {
     label: "Lagos State Polytechnic, Ikorodu",   	
     value: "Lagos State Polytechnic, Ikorodu",   	
    },
    {
     label: "	Mai-Idris Alooma Polytechnic, Geidam, Yobe State",   	
     value: "	Mai-Idris Alooma Polytechnic, Geidam, Yobe State",   	
    },
    {
     label: "Moshood Abiola Polytechnic, Abeokuta",   	
     value: "Moshood Abiola Polytechnic, Abeokuta",   	
    },
    {
     label: "Nasarawa State College of Agriculture and Technology, Lafia",   	
     value: "Nasarawa State College of Agriculture and Technology, Lafia",   	
    },
    {
     label: "Niger State Polytechnic, Zungeru",   	
     value: "Niger State Polytechnic, Zungeru",   	
    },
    {
     label: "Nuhu Bamalli Polytechnic, Zaria,",   	
     value: "Nuhu Bamalli Polytechnic, Zaria,",   	
    },
    {
     label: "Ogun State Institute of Technology, Igbesa",   	
     value: "Ogun State Institute of Technology, Igbesa",   	
    },
    {
     label: "Ogun State Polytechnic, Ipokia",   	
     value: "Ogun State Polytechnic, Ipokia",   	
    },
    {
     label: "Oke-Ogun Polytechnic, Shaki,Oyo State",   	
     value: "Oke-Ogun Polytechnic, Shaki,Oyo State",   	
    },
    {
     label: "Osun State College of Technology,P.M.B. 1011, Esa  Oke, Osun State",   	
     value: "Osun State College of Technology,P.M.B. 1011, Esa  Oke, Osun State",   	
    },
    {
     label: "Osun State Polytechnic, Iree,P.M.B. 301, Iree, Osun State",   	
     value: "Osun State Polytechnic, Iree,P.M.B. 301, Iree, Osun State",   	
    },
    {
     label: "Oyo State College of Agriculture and Technology, Igbo Ora",   	
     value: "Oyo State College of Agriculture and Technology, Igbo Ora",   	
    },
    {
     label: "Plateau State Polytechnic, Barkin-Ladi,P.M.B. 02023, Jos, Plateau State",   	
     value: "Plateau State Polytechnic, Barkin-Ladi,P.M.B. 02023, Jos, Plateau State",   	
    },
    {
     label: "Port-Harcourt PolytechnicP.M.B. 5936, Port-Harcourt,Rivers State",   	
     value: "Port-Harcourt PolytechnicP.M.B. 5936, Port-Harcourt,Rivers State",   	
    },
    {
     label: "Ramat Polytechnic, Maiduguri",   	
     value: "Ramat Polytechnic, Maiduguri",   	
    },
    {
     label: "Rufus Giwa Polytechnic, Owo,P.M.B. 1019 Owo Ondo State",   	
     value: "Rufus Giwa Polytechnic, Owo,P.M.B. 1019 Owo Ondo State",   	
    },
    {
     label: "Taraba State Polytechnic, Suntai",   	   	
     value: "Taraba State Polytechnic, Suntai",   	   	
    },
    {
     label: "The Polytechnic Ibadan,P.M.B. 22, Ibadan, Oyo State",   	
     value: "The Polytechnic Ibadan,P.M.B. 22, Ibadan, Oyo State",   	
    },
    {
     label: "Umaru Ali Shinkafi Polytechnic, Sokoto",   	
     value: "Umaru Ali Shinkafi Polytechnic, Sokoto",   	
    },
    {
     label: "Zamfara State College of Arts and Science, Sani Abacha Way, Sokoto Road, Gusau",   	
     value: "Zamfara State College of Arts and Science, Sani Abacha Way, Sokoto Road, Gusau",   	
    },
    {
     label: "Al-Hikma Polytechnic Karu Mararaba Gurku, Karu, Nasarawa State",   	
     value: "Al-Hikma Polytechnic Karu Mararaba Gurku, Karu, Nasarawa State",   	
    },
    {
     label: "Allover Central Polytechnic, Sango Ota,Sango Ota, Ogun State",   	
     value: "Allover Central Polytechnic, Sango Ota,Sango Ota, Ogun State",   	
    },
    {
     label: "Ajayi Polytechnic, Ikere Ekiti, Ekiti State",   	
     value: "Ajayi Polytechnic, Ikere Ekiti, Ekiti State",   	
    },
    {
     label: "Ashi Polytechnic, Benue State",   	
     value: "Ashi Polytechnic, Benue State",   	
    },
    {
     label: "Best Solution Polytechnic, Akure",   	
     value: "Best Solution Polytechnic, Akure",   	
    },
    {
     label: "Bellarks Polytechnic, Kwale, Delta State",   	
     value: "Bellarks Polytechnic, Kwale, Delta State",   	
    },
    {
     label: "Bolmor Polytechnic, Oyo State",   	
     value: "Bolmor Polytechnic, Oyo State",   	
    },
    {
     label: "Citi Polytechnic, Dutse Alhaji, FCT Abuja",   	
     value: "Citi Polytechnic, Dutse Alhaji, FCT Abuja",   	
    },
    {
     label: "Coastal Polytechnic Apapa, Apapa Lagos",   	
     value: "Coastal Polytechnic Apapa, Apapa Lagos",   	
    },
    {
     label: "Covenant Polytechnic, Aba, Abia State",   	
     value: "Covenant Polytechnic, Aba, Abia State",   	
    },
    {
     label: "Crown Polytechnic, Ado-Ekiti, Ekiti State",   	
     value: "Crown Polytechnic, Ado-Ekiti, Ekiti State",   	
    },
    {
     label: "Dorben Polytechnic, Abuja, Garki, Bwari, Abuja",   	
     value: "Dorben Polytechnic, Abuja, Garki, Bwari, Abuja",   	
    },
    {
     label: "Eastern Polytechnic, Rumuokwurusi, Port Harcourt, Rivers State",   	
     value: "Eastern Polytechnic, Rumuokwurusi, Port Harcourt, Rivers State",   	
    },
    {
     label: "Enville Institute of Management and Technology, Ikeja, Lagos",   	
     value: "Enville Institute of Management and Technology, Ikeja, Lagos",   	
    },
    {
     label: "Fidei Polytechnic, Gboko, Benue State",   	
     value: "Fidei Polytechnic, Gboko, Benue State",   	
    },
    {
     label: "Gboko Polytechnic, Gboko, Benue State",   	
     value: "Gboko Polytechnic, Gboko, Benue State",   	
    },
    {
     label: "Global Polytechnic, Akure, Ondo State",   	
     value: "Global Polytechnic, Akure, Ondo State",   	
    },
    {
     label: "Grace Polytechnic, Surulere, Lagos State",   	
     value: "Grace Polytechnic, Surulere, Lagos State",   	
    },
    {
     label: "Graceland Polytechnic, Offa, Kwara State",   	
     value: "Graceland Polytechnic, Offa, Kwara State",   	
    },
    {
     label: "Grundtvig Polytechnic, Oba Anambra State",   	
     value: "Grundtvig Polytechnic, Oba Anambra State",   	
    },
    {
     label: "Harry Pass Polytechnic, Benue State",   	
     value: "Harry Pass Polytechnic, Benue State",   	
    },
    {
     label: "Heritage Polytechnic, Ikot Udota, Eket, Akwa Ibom State",   	
     value: "Heritage Polytechnic, Ikot Udota, Eket, Akwa Ibom State",   	
    },
    {
     label: "Ibadan City Polytechnic, Ibadan, Oyo State",   	
     value: "Ibadan City Polytechnic, Ibadan, Oyo State",   	
    },
    {
     label: "I-CON Universal Polytechnic Osogbo, Osun State",   	
     value: "I-CON Universal Polytechnic Osogbo, Osun State",   	
    },
    {
     label: "Igbajo Polytechnic, Igbajo, Osun State",   	
     value: "Igbajo Polytechnic, Igbajo, Osun State",   	
    },
    {
     label: "Interlink Polytechnic, Ijebu-Jesa, Osun State",   	
     value: "Interlink Polytechnic, Ijebu-Jesa, Osun State",   	
    },
    {
     label: "Iwo City Polytechnic, Iwo, Osun State",   	
     value: "Iwo City Polytechnic, Iwo, Osun State",   	
    },
    {
     label: "Kings Polytechnic, Ubiaja, Edo State",   	
     value: "Kings Polytechnic, Ubiaja, Edo State",   	
    },
    {
     label: "Ihechukwu Madubuike Institute of Technology,Isuochi, Abia State",   	
     value: "Ihechukwu Madubuike Institute of Technology,Isuochi, Abia State",   	
    },
    {
     label: "Kalac Christal Polytechnic, Sangotedo, Lekki, Lagos State",   	
     value: "Kalac Christal Polytechnic, Sangotedo, Lekki, Lagos State",   	
    },
    {
     label: "Landmark Polytechnic, Ogun State",   	
     value: "Landmark Polytechnic, Ogun State",   	
    },
    {
     label: "Lagos City Polytechnic, Ikeja, Lagos State",   	
     value: "Lagos City Polytechnic, Ikeja, Lagos State",   	
    },
    {
     label: "Lens Polytechnic, Offa, Kwara State",   	
     value: "Lens Polytechnic, Offa, Kwara State",   	
    },
    {
     label: "Lighthouse Polytechnic, Benin City, Edo State",   	
     value: "Lighthouse Polytechnic, Benin City, Edo State",   	
    },
    {
     label: "Marist Polytechnic, Umuchigbo, Iji-Nike, Enugu State",   	
     value: "Marist Polytechnic, Umuchigbo, Iji-Nike, Enugu State",   	
    },
    {
     label: "Mater Dei Polytechnic,  Enugu State",   	
     value: "Mater Dei Polytechnic,  Enugu State",   	
    },
    {
     label: "Nacabs Polytechnic, Akwanga, Nasarawa State",   	
     value: "Nacabs Polytechnic, Akwanga, Nasarawa State",   	
    },
    {
     label: "Nogak Polytechnic, Ikom, Cross Rivers State",   	
     value: "Nogak Polytechnic, Ikom, Cross Rivers State",   	
    },
    {
     label: "Novelty Polytechnic Kishi, Oyo State",   	
     value: "Novelty Polytechnic Kishi, Oyo State",   	
    },
    {
     label: "Our Saviour Institute of Science, Agriculture & Technology, Enugu State",   	
     value: "Our Saviour Institute of Science, Agriculture & Technology, Enugu State",   	
    },
    {
     label: "Redeemers College of Technology and Management (RECTEM), Ogun State",   	
     value: "Redeemers College of Technology and Management (RECTEM), Ogun State",   	
    },
    {
     label: "Ronik Polytechnic, Ikeja, Lagos State",   	
     value: "Ronik Polytechnic, Ikeja, Lagos State",   	
    },
    {
     label: "Saf Polytechnic, Oyo State",   	
     value: "Saf Polytechnic, Oyo State",   	
    },
    {
     label: "Savanah Institute of Technology, Abakaliki, Ebonyi State",   	
     value: "Savanah Institute of Technology, Abakaliki, Ebonyi State",   	
    },
    {
     label: "Shaka Polytechnic, Benin City, Edo State",   	
     value: "Shaka Polytechnic, Benin City, Edo State",   	
    },
    {
     label: "Speedway Polytechnic, Osoba Ojudu, Ogun State",   	
     value: "Speedway Polytechnic, Osoba Ojudu, Ogun State",   	
    },
    {
     label: "St. Mary Polytechnic, Suleja, Niger State",   	
     value: "St. Mary Polytechnic, Suleja, Niger State",   	
    },
    {
     label: "Stars Polytechnic, Ogun State",   	
     value: "Stars Polytechnic, Ogun State",   	
    },
    {
     label: "Southern Atlantic Polytechnic, Uyo, Akwa Ibom State",   	
     value: "Southern Atlantic Polytechnic, Uyo, Akwa Ibom State",   	
    },
    {
     label: "Sure Foundation Polytechnic, Ikot Akai, Akwa Ibom State",   	
     value: "Sure Foundation Polytechnic, Ikot Akai, Akwa Ibom State",   	
    },
    {
     label: "Temple Gate Polytechnic, Aba, Abia State",   	
     value: "Temple Gate Polytechnic, Aba, Abia State",   	
    },
    {
     label: "The Polytechnic, Igbo-Owu, Kwara State",   	
     value: "The Polytechnic, Igbo-Owu, Kwara State",   	
    },
    {
     label: "The Polytechnic, Ile Ife, Osun State",   	
     value: "The Polytechnic, Ile Ife, Osun State",   	
    },
    {
     label: "The Polytechnic Iresi, Osun State",   	
     value: "The Polytechnic Iresi, Osun State",   	
    },
    {
     label: "Timeon Kairos Polytechnic Lagos, Abule Egba Lagos",   	
     value: "Timeon Kairos Polytechnic Lagos, Abule Egba Lagos",   	
    },
    {
     label: "Tower Polytechnic, Ibadan, Oyo State",   	
     value: "Tower Polytechnic, Ibadan, Oyo State",   	
    },
    {
     label: "Trinity Polytechnic Uyo, Akwa Ibom State",   	
     value: "Trinity Polytechnic Uyo, Akwa Ibom State",   	
    },
    {
     label: "Uma Ukpai Polytechnic, Asaga Ohafia, Abia State",   	
     value: "Uma Ukpai Polytechnic, Asaga Ohafia, Abia State",   	
    },
    {
     label: "Uyo City Polytechnic, Uyo, Akwa Ibom State",   	
     value: "Uyo City Polytechnic, Uyo, Akwa Ibom State",   	
    },
    {
     label: "The Polytechnic Otada Adoka, Benue State",   	
     value: "The Polytechnic Otada Adoka, Benue State",   	
    },
    {
     label: "Valley View Polytechnic, Ohafia, Abia State",   	
     value: "Valley View Polytechnic, Ohafia, Abia State",   	
    },
    {
     label: "Villanova Polytechnic, Osun State",   	
     value: "Villanova Polytechnic, Osun State",   	
    },
    {
     label: "Wolex Polytechnic, Iwo, Osun State",   	  	
     value: "Wolex Polytechnic, Iwo, Osun State",   	  	
    },
    {
     label: "College of Education (Technical), Lafiagi",   	  	
     value: "College of Education (Technical), Lafiagi",   	  	
    },
    {
     label: "Federal College of Education, Asaba",   	  	
     value: "Federal College of Education, Asaba",   	  	
    },
    {
     label: "Federal College of Education, Kano",   	  	
     value: "Federal College of Education, Kano",   	  	
    },
    {
     label: "Federal College of Education, Oyo",   	  	
     value: "Federal College of Education, Oyo",   	  	
    },
    {
     label: "College of Education, Gindiri",   	  	
     value: "College of Education, Gindiri",   	  	
    },
    {
     label: "College of Education, Hong",   	  	
     value: "College of Education, Hong",   	  	
    },
    {
     label: "Tai Solarin College of Education, Ijebu-Ode",   	  	
     value: "Tai Solarin College of Education, Ijebu-Ode",   	  	
    },
    {
     label: "College of Education, Ikere-Ekiti",   	  	
     value: "College of Education, Ikere-Ekiti",   	  	
    },
    {
     label: "Ebonyi State College of Education",   	  	
     value: "Ebonyi State College of Education",   	  	
    },
    {
     label: "College of Education, Warri",   	  	
     value: "College of Education, Warri",   	  	
    },
    {
     label: "FCT College of Education, Zuba",   	  	
     value: "FCT College of Education, Zuba",   	  	
    },
    {
     label: "Delar College of Education",   	  	
     value: "Delar College of Education",   	  	
    },
    {
     label: "Federal College of Education, Abeokuta",   	  	
     value: "Federal College of Education, Abeokuta",   	  	
    },
    {
     label: "Federal College of Education, Eha-Amufu",   	  	
     value: "Federal College of Education, Eha-Amufu",   	  	
    },
    {
     label: "Federal College of Education, Gombe",   	  	
     value: "Federal College of Education, Gombe",   	  	
    },
    {
     label: "Federal College of Education, Kontagora",   	  	
     value: "Federal College of Education, Kontagora",   	  	
    },
    {
     label: "Federal College of Education, Okene",   	  	
     value: "Federal College of Education, Okene",   	  	
    },
    {
     label: "Federal College of Education , Omoku",   	  	
     value: "Federal College of Education , Omoku",   	  	
    },
    {
     label: "Federal College of Education , Potiskum",   	  	
     value: "Federal College of Education , Potiskum",   	  	
    },
    {
     label: "Nasarrawa State College of Education, Akwanga",   	  	
     value: "Nasarrawa State College of Education, Akwanga",   	  	
    },
    {
     label: "Isa Kaita College of Education, Dutsin-Ma",   	  	
     value: "Isa Kaita College of Education, Dutsin-Ma",   	  	
    },
    {
     label: "College of Education, Ekiadolor-Benin",   	  	
     value: "College of Education, Ekiadolor-Benin",   	  	
    },
    {
     label: "College of Education, Gashua, Damaturu",   	  	
     value: "College of Education, Gashua, Damaturu",   	  	
    },
    {
     label: "Kaduna State College of Education, Gidan-Waya, Kafanchan",   	  	
     value: "Kaduna State College of Education, Gidan-Waya, Kafanchan",   	  	
    },
    {
     label: "Osun State College of Education, Ilesa",   	  	
     value: "Osun State College of Education, Ilesa",   	  	
    },
    {
     label: "College of Education Oro",   	  	
     value: "College of Education Oro",   	  	
    },
    {
     label: "College of Education, katsina-Ala",   	  	
     value: "College of Education, katsina-Ala",   	  	
    },
    {
     label: "Saadatu Rimi College of Education, Kumbotso, Kano",   	  	
     value: "Saadatu Rimi College of Education, Kumbotso, Kano",   	  	
    },
    {
     label: "Nwafor Orizu College of Education, Nsugbe",   	  	
     value: "Nwafor Orizu College of Education, Nsugbe",   	  	
    },
    {
     label: "Adeniran Ogunsanya College of Education, Otto/Ijanikin",   	  	
     value: "Adeniran Ogunsanya College of Education, Otto/Ijanikin",   	  	
    },
    {
     label: "Alvan Ikoku College of Education, Owerri",   	  	
     value: "Alvan Ikoku College of Education, Owerri",   	  	
    },
    {
     label: "Emmanuel Alayande College of Education (EACOED), Oyo",   	  	
     value: "Emmanuel Alayande College of Education (EACOED), Oyo",   	  	
    },
    {
     label: "College of Education, Waka BIU",   	  	
     value: "College of Education, Waka BIU",   	  	
    },
    {
     label: "City College of Education, Mararaba",   	  	
     value: "City College of Education, Mararaba",   	  	
    },
    {
     label: "Federal College of Education, Akoka",   	  	
     value: "Federal College of Education, Akoka",   	  	
    },
    {
     label: "Federal College of Education, Bichi",   	  	
     value: "Federal College of Education, Bichi",   	  	
    },
    {
     label: "Federal College of Education, Gusau",   	  	
     value: "Federal College of Education, Gusau",   	  	
    },
    {
     label: "Federal College of Education, Katsina",   	  	
     value: "Federal College of Education, Katsina",   	  	
    },
    {
     label: "Federal College of Education, Obudu",   	  	
     value: "Federal College of Education, Obudu",   	  	
    },
    {
     label: "Adeyemi College of Education, Ondo",   	  	
     value: "Adeyemi College of Education, Ondo",   	  	
    },
    {
     label: "Federal College of Education, Pankshin",   	  	
     value: "Federal College of Education, Pankshin",   	  	
    },
    {
     label: "Federal College of Education, Yola",   	  	
     value: "Federal College of Education, Yola",   	  	
    },
    {
     label: "Delta State College of Education, Agbor",   	  	
     value: "Delta State College of Education, Agbor",   	  	
    },
    {
     label: "Federal College of Education, Zaria",   	  	
     value: "Federal College of Education, Zaria",   	  	
    },
    {
     label: "Akwa Ibom State College of Education, Afahansit",   	  	
     value: "Akwa Ibom State College of Education, Afahansit",   	  	
    },
    {
     label: "Kogi State College of Education, Ankpa",   	  	
     value: "Kogi State College of Education, Ankpa",   	  	
    },
    {
     label: "Adamu Augie College of Education, Argungu",   	  	
     value: "Adamu Augie College of Education, Argungu",   	  	
    },
    {
     label: "Aminu Sale College of Education, Azare",   	  	
     value: "Aminu Sale College of Education, Azare",   	  	
    },
    {
     label: "Umar Ibn Ibrahim El-Kanemi College of Education, Science and Technology, Bama",   	  	
     value: "Umar Ibn Ibrahim El-Kanemi College of Education, Science and Technology, Bama",   	  	
    },
    {
     label: "Nigerian Army School of Education (NASE), Ilorin",   	  	
     value: "Nigerian Army School of Education (NASE), Ilorin",   	  	
    },
    {
     label: "Zamfara State College of Education, Maru",   	  	
     value: "Zamfara State College of Education, Maru",   	  	
    },
    {
     label: "Jigawa State College of Education, Gumel",   	  	
     value: "Jigawa State College of Education, Gumel",   	  	
    },
    {
     label: "Niger State College of Education, Minna",   	  	
     value: "Niger State College of Education, Minna",   	  	
    },
    {
     label: "Shehu Shagari College of Education, Sokoto",   	  	
     value: "Shehu Shagari College of Education, Sokoto",   	  	
    },
    {
     label: "Ansar-Ud-Deen College of Education, Isolo",   	  	
     value: "Ansar-Ud-Deen College of Education, Isolo",   	  	
    },
    {
     label: "Yewa Central College of Education, Ayetoro, Abeokuta",   	  	
     value: "Yewa Central College of Education, Ayetoro, Abeokuta",   	  	
    },
    {
     label: "Federal College of Education (T), Umunze",   	  	
     value: "Federal College of Education (T), Umunze",   	  	
    },
    {
     label: "Upland College of Education, Badagry",   	  	
     value: "Upland College of Education, Badagry",   	  	
    },
    {
     label: "College of Education, Arochukwu, Abia",   	  	
     value: "College of Education, Arochukwu, Abia",   	  	
    },
    {
     label: "College of Education, Ila-Orangun, Osun State",   	  	
     value: "College of Education, Ila-Orangun, Osun State",   	  	
    },
    {
     label: "Michael Otedola College of Prim. Education, Lagos",   	  	
     value: "Michael Otedola College of Prim. Education, Lagos",   	  	
    },
    {
     label: "Kashim Ibrahim College of Education",   	  	
     value: "Kashim Ibrahim College of Education",   	  	
    },
    {
     label: "Delta State College of Physical Education, Mosogar",   	  	
     value: "Delta State College of Physical Education, Mosogar",   	  	
    },
    {
     label: "Enugu State College of Education (T), Enugu",   	  	
     value: "Enugu State College of Education (T), Enugu",   	  	
    },
    {
     label: "Cross River State College of Education, Akampa",   	  	
     value: "Cross River State College of Education, Akampa",   	  	
    },
    {
     label: "Edo State College of Education, Igueben",   	  	
     value: "Edo State College of Education, Igueben",   	  	
    },
    {
     label: "Isaac Jasper Boro College of Education, Sagbama",   	  	
     value: "Isaac Jasper Boro College of Education, Sagbama",   	  	
    },
    {
     label: "Kogi State College of Education, Kabba",   	  	
     value: "Kogi State College of Education, Kabba",   	  	
    },
    {
     label: "Osisa Tech. College of Education",   	  	
     value: "Osisa Tech. College of Education",   	  	
    },
    {
     label: "St. Augustine College of Education Akoka, Lagos",   	  	
     value: "St. Augustine College of Education Akoka, Lagos",   	  	
    },
    {
     label: "African Thinkers Community of Inquiry College of Education, Enugu",   	  	
     value: "African Thinkers Community of Inquiry College of Education, Enugu",   	  	
    },
    {
     label: "Muftau Olanihun College of Education, Ibadan",   	  	
     value: "Muftau Olanihun College of Education, Ibadan",   	  	
    },
    {
     label: "Havard Wilson College of Education, Aba",   	  	
     value: "Havard Wilson College of Education, Aba",   	  	
    },
    {
     label: "Muhyideen College of Education, Ilorin",   	  	
     value: "Muhyideen College of Education, Ilorin",   	  	
    },
    {
     label: "Corner Stone College of Education, Ikeja",   	  	
     value: "Corner Stone College of Education, Ikeja",   	  	
    },
    {
     label: "Peaceland College of Education, Enugu",   	  	
     value: "Peaceland College of Education, Enugu",   	  	
    },
    {
     label: "The College of Education, Nsukka",   	  	
     value: "The College of Education, Nsukka",   	  	
    },
    {
     label: "Unity College of Education, Aukpa Adoka, Benue",   	  	
     value: "Unity College of Education, Aukpa Adoka, Benue",   	  	
    },
    {
     label: "DIAMOND COLLEGE OF EDUCATION, ABA",   	  	
     value: "DIAMOND COLLEGE OF EDUCATION, ABA",   	  	
    },
    {
     label: "National Teachers Institute(NTI)",   	  	
     value: "National Teachers Institute(NTI)",   	  	
    },
    {
     label: "Kingsey College of Education, Ilorin, Kwara State",   	  	
     value: "Kingsey College of Education, Ilorin, Kwara State",   	  	
    },
    {
     label: "ECWA College of Education, Jos (ECollege of EducationJ)",   	  	
     value: "ECWA College of Education, Jos (ECollege of EducationJ)",   	  	
    },
    {
     label: "FESTMED COLLEGE OF EDUCATION, ONDO STATE",   	  	
     value: "FESTMED COLLEGE OF EDUCATION, ONDO STATE",   	  	
    },
    {
     label: "BETHEL College of Education IJARE, ONDO",   	  	
     value: "BETHEL College of Education IJARE, ONDO",   	  	
    },
    {
     label: "STEADY FLOW College of Education, IKOM",   	  	
     value: "STEADY FLOW College of Education, IKOM",   	  	
    },
    {
     label: "AL HIKMA COLLEGE OF EDUCATION, ANKPA",   	  	
     value: "AL HIKMA COLLEGE OF EDUCATION, ANKPA",   	  	
    },
    {
     label: "Global College of Education, Bukuru",   	  	
     value: "Global College of Education, Bukuru",   	  	
    },
    {
     label: "Kwararafa College of Education, Otukpo",   	  	
     value: "Kwararafa College of Education, Otukpo",   	  	
    },
    {
     label: "College of Education, Billiri",   	  	
     value: "College of Education, Billiri",   	  	
    },
    {
     label: "Lessel College of Education Gboko",   	  	
     value: "Lessel College of Education Gboko",   	  	
    },
    {
     label: "College of Education, Zing",   	  	
     value: "College of Education, Zing",   	  	
    },
    {
     label: "ECWA College of Education Igbaja",   	  	
     value: "ECWA College of Education Igbaja",   	  	
    },
    {
     label: "PAN African College of Education Offa",   	  	
     value: "PAN African College of Education Offa",   	  	
    },
    {
     label: "Corona College of Education Lekki",   	  	
     value: "Corona College of Education Lekki",   	  	
    },
    {
     label: "College of Education Oju",   	  	
     value: "College of Education Oju",   	  	
    },
    {
     label: "EEI College of Education Otukpo",   	  	
     value: "EEI College of Education Otukpo",   	  	
    },
    {
     label: "Sinai College of Education & Ent. Studies Gboko, Benue",   	  	
     value: "Sinai College of Education & Ent. Studies Gboko, Benue",   	  	
    },
    {
     label: "MCF College of Education Agbarha-Otor, Delta",   	  	
     value: "MCF College of Education Agbarha-Otor, Delta",   	  	
    },
    {
     label: "Assanusiya College of Education, Odeomu, Osun",   	  	
     value: "Assanusiya College of Education, Odeomu, Osun",   	  	
    },
    {
     label: "College of Education, Ero, Akure",   	  	
     value: "College of Education, Ero, Akure",   	  	
    },
    {
     label: "Calvin Foundation College of Education",   	  	
     value: "Calvin Foundation College of Education",   	  	
    },
    {
     label: "Best Legacy College of Education Ogbomoso",   	  	
     value: "Best Legacy College of Education Ogbomoso",   	  	
    },
    {
     label: "Angel Crown College of Education",   	  	
     value: "Angel Crown College of Education",   	  	
    },
    {
     label: "Elder Oyama Memorial College of Education, Ofat",   	  	
     value: "Elder Oyama Memorial College of Education, Ofat",   	  	
    },
    {
     label: "College of Education, Moro, Ife-North",   	  	
     value: "College of Education, Moro, Ife-North",   	  	
    },
    {
     label: "Royal City College of Education, Iyesi-Ota",   	  	
     value: "Royal City College of Education, Iyesi-Ota",   	  	
    },
    {
     label: "ONIT College of Education, Abagana",   	  	
     value: "ONIT College of Education, Abagana",   	  	
    },
    {
     label: "Nana Aishat Memorial College of Education",   	  	
     value: "Nana Aishat Memorial College of Education",   	  	
    },
    {
     label: "Top-Most College of Education, Ipaja-Agbado",   	  	
     value: "Top-Most College of Education, Ipaja-Agbado",   	  	
    },
    {
     label: "Apa College of Education, Aido",   	  	
     value: "Apa College of Education, Aido",   	  	
    },
    {
     label: "Awori District College of Education",   	  	
     value: "Awori District College of Education",   	  	
    },
    {
     label: "Hill College of Education, Gwanje, Akwanga",   	  	
     value: "Hill College of Education, Gwanje, Akwanga",   	  	
    },
    {
     label: "Aminu Kano College of Education",   	  	
     value: "Aminu Kano College of Education",   	  	
    },
    {
     label: "Murtadha College of Education, Olodo",   	  	
     value: "Murtadha College of Education, Olodo",   	  	
    },
    {
     label: "Islamic College of Education, Potiskum",   	  	
     value: "Islamic College of Education, Potiskum",   	  	
    },
    {
     label: "Royal College of Education, Ikeja, Lagos State",   	  	
     value: "Royal College of Education, Ikeja, Lagos State",   	  	
    },
    {
     label: "Hassan Usman Katsina Polytechnic",   	  	
     value: "Hassan Usman Katsina Polytechnic",   	  	
    },
    {
     label: "Plateau State Polytechnic",  
     value: "Plateau State Polytechnic",  
    },
    {
     label: "Bayo Tijani College of Education, Lagos",   	  	 	  	
     value: "Bayo Tijani College of Education, Lagos",   	  	 	  	
    },
    {
     label: "Kano State Polytechnic, Kano",   	  	 	  	
     value: "Kano State Polytechnic, Kano",   	  	 	  	
    },
    {
     label: "Waziri Umaru Federal Polytechnic",   	  	 	  	
     value: "Waziri Umaru Federal Polytechnic",   	  	 	  	
    },
    {
     label: "Ramat Polytechnic",   	  	 	  	
     value: "Ramat Polytechnic",   	  	 	  	
    },
    {
     label: "Kaduna Polytechnics",   	  	 	  	
     value: "Kaduna Polytechnics",   	  	 	  	
    },
    {
     label: "Abubakar Tatari Polytechnic",   	  	 	  	
     value: "Abubakar Tatari Polytechnic",   	  	 	  	
    },
    {
     label: "Nuhu Bamalli Polytechnic",   	  	 	  	
     value: "Nuhu Bamalli Polytechnic",   	  	 	  	
    },
    {
     label: "Ipere College of Education, Agyaragu",   	  	 	  	
     value: "Ipere College of Education, Agyaragu",   	  	 	  	
    },
    {
     label: "Piaget College of Education",   	  	 	  	
     value: "Piaget College of Education",   	  	 	  	
    },
    {
     label: "Biga College of Education",   	  	 	  	
     value: "Biga College of Education",   	  	 	  	
    },
    {
     label: "Al-Ibadan College of Education",   	  	 	  	
     value: "Al-Ibadan College of Education",   	  	 	  	
    },
    {
     label: "College of Education Kura",   	  	 	  	
     value: "College of Education Kura",   	  	 	  	
    },
    {
     label: "Sunnah College of Education",   	  	 	  	
     value: "Sunnah College of Education",   	  	 	  	
    },
    {
     label: "Oswald Waller College of Education Shendam",   	  	 	  	
     value: "Oswald Waller College of Education Shendam",   	  	 	  	
    },
    {
     label: "Imam Saidu College of Education, Funtua",   	  	 	  	
     value: "Imam Saidu College of Education, Funtua",   	  	 	  	
    },
    {
     label: "Bilyaminu Othman College of Education Dass",   	  	 	  	
     value: "Bilyaminu Othman College of Education Dass",   	  	 	  	
    },
    {
     label: "The Polytechnic Iree, Osun State",   	  	 	  	
     value: "The Polytechnic Iree, Osun State",   	  	 	  	
    },
    {
     label: "Bogoro College of Education",   	  	 	  	
     value: "Bogoro College of Education",   	  	 	  	
    },
    {
     label: "Adesina College of Education, Share, Kwara State",   	  	 	  	
     value: "Adesina College of Education, Share, Kwara State",   	  	 	  	
    },
    {
     label: "Covenant College of Education (CCOE)",   	  	 	  	
     value: "Covenant College of Education (CCOE)",   	  	 	  	
    },
    {
     label: "JIGAWA STATE COLLEGE OF EDUCATION AND LEGAL STUDIES, RINGIM",   	  	 	  	
     value: "JIGAWA STATE COLLEGE OF EDUCATION AND LEGAL STUDIES, RINGIM",   	  	 	  	
    },
    {
     label: "CRESTFIELD COLLEGE OF EDUCATION",   	  	 	  	
     value: "CRESTFIELD COLLEGE OF EDUCATION",   	  	 	  	
    },
    {
     label: "GRACE COLLEGE OF EDUCATION",   	  	 	  	
     value: "GRACE COLLEGE OF EDUCATION",   	  	 	  	
    },
    {
     label: "AFRICAN CHURCH COLLEGE OF EDUCATION",   	  	 	  	
     value: "AFRICAN CHURCH COLLEGE OF EDUCATION",   	  	 	  	
    },
    {
     label: "Kano State College of Education and Remedial Studies, Kano",   	  	 	  	
     value: "Kano State College of Education and Remedial Studies, Kano",   	  	 	  	
    },
    {
     label: "College of Education, Lanlate, Oyo State",   	  	 	  	
     value: "College of Education, Lanlate, Oyo State",   	  	 	  	
    },
    {
     label: "Climax College of Education, Bauchi",   	  	 	  	
     value: "Climax College of Education, Bauchi",   	  	 	  	
    },
    {
     label: "College of Education and Legal Studies",   	  	 	  	
     value: "College of Education and Legal Studies",   	  	 	  	
    },
    {
     label: "Federal College of Education Sokoto",   	  	 	  	
     value: "Federal College of Education Sokoto",   	  	 	  	
    },
    {
     label: "Federal College of Education Bauchi",   	  	 	  	
     value: "Federal College of Education Bauchi",   	  	 	  	
    },
    {
     label: "Federal College of Education (T), ISU Ebonyi State",   	  	 	  	
     value: "Federal College of Education (T), ISU Ebonyi State",   	  	 	  	
    },
    {
     label: "Annur College of Education Kano	",   	  	 	  	
     value: "Annur College of Education Kano	",   	  	 	  	
    },
    {
     label: "College of Education Ilemona",   	  	 	  	
     value: "College of Education Ilemona",   	  	 	  	
    },
    {
     label: "Ebenezer College of Education Amangwu",   	  	 	  	
     value: "Ebenezer College of Education Amangwu",   	  	 	  	
    },
    {
     label: "JIBWIS College of Education Gombe",   	  	 	  	
     value: "JIBWIS College of Education Gombe",   	  	 	  	
    },
    {
     label: "Kogi East College of Education",   	  	 	  	
     value: "Kogi East College of Education",   	  	 	  	
    },
    {
     label: "Gboko College of Education Benue State",   	  	 	  	
     value: "Gboko College of Education Benue State",   	  	 	  	
    },
    {
     label: "Hope and Anchor College of Education",   	  	 	  	
     value: "Hope and Anchor College of Education",   	  	 	  	
    },
    {
     label: "Elizabeth Memorial College of Education Nsukka",   	  	 	  	
     value: "Elizabeth Memorial College of Education Nsukka",   	  	 	  	
    },
    {
     label: "Ilori College of Education , Ede",   	  	 	  	
     value: "Ilori College of Education , Ede",   	  	 	  	
    },
    {
     label: "Sarkin Yama Community College of Education",   	  	 	  	
     value: "Sarkin Yama Community College of Education",   	  	 	  	
    },
    {
     label: "Umar Bun Khatab College of Education, Tudun Nupawa, Kaduna",   	  	 	  	
     value: "Umar Bun Khatab College of Education, Tudun Nupawa, Kaduna",   	  	 	  	
    },
    {
     label: "Peacock College of Education Jalingo",   	  	 	  	
     value: "Peacock College of Education Jalingo",   	  	 	  	
    },
    {
     label: "Peace College Of Education Ankpa",   	  	 	  	
     value: "Peace College Of Education Ankpa",   	  	 	  	
    },
    {
     label: "Moje College of Education, Erin-Ile",   	  	 	  	
     value: "Moje College of Education, Erin-Ile",   	  	 	  	
    },
    {
     label: "Uli College of Education, Uli",   	  	 	  	
     value: "Uli College of Education, Uli",   	  	 	  	
    },
    {
     label: "Lifegate College of Education, Asa",   	  	 	  	
     value: "Lifegate College of Education, Asa",   	  	 	  	
    },
    {
     label: "JIBWIS College of Education, Jama are",   	  	 	  	
     value: "JIBWIS College of Education, Jama are",   	  	 	  	
    },
    {
     label: "Dala College of Education, Kano",   	  	 	  	
     value: "Dala College of Education, Kano",   	  	 	  	
    },
    {
     label: "Hamzainab College of Education, Oshogbo",   	  	 	  	
     value: "Hamzainab College of Education, Oshogbo",   	  	 	  	
    },
    {
     label: "College of Education, Darazo",   	  	 	  	
     value: "College of Education, Darazo",   	  	 	  	
    },
    {
     label: "Abubakar Garba Zagada- Zagada College of Education, Bajoga",   	  	 	  	
     value: "Abubakar Garba Zagada- Zagada College of Education, Bajoga",   	  	 	  	
    },
    {
     label: "Federal College of Education Osun",   	  	 	  	
     value: "Federal College of Education Osun",   	  	 	  	
    },
    {
     label: "Federal College of Education Edo",   	  	 	  	
     value: "Federal College of Education Edo",   	  	 	  	
    },
    {
     label: "Kashim Ibrahim College of Education",   	  	 	  	
     value: "Kashim Ibrahim College of Education",   	  	 	  	
    },
    {
     label: "Federal College of Education, Odugbo, Benue State",   	  	 	  	
     value: "Federal College of Education, Odugbo, Benue State",   	  	 	  	
    },
    {
     label: "College of Education Oro",   	  	 	  	
     value: "College of Education Oro",   	  	 	  	
    },
    {
     label: "Imo State College of Education, Ihitte/Uboma",   	  	 	  	
     value: "Imo State College of Education, Ihitte/Uboma",   	  	 	  	
    },
    {
     label: "Imam Hamzat College of Education, Ilorin",   	  	 	  	
     value: "Imam Hamzat College of Education, Ilorin",   	  	 	  	
    },
    {
     label: "Metro College of Education, Adogi-Lafia",   	  	 	  	
     value: "Metro College of Education, Adogi-Lafia",   	  	 	  	
    },
    {
     label: "College of Sharia and Arabic Studies",   	  	 	  	
     value: "College of Sharia and Arabic Studies",   	  	 	  	
    },
    {
     label: "Abdullahi Maikano College of Education, Wase",   	  	 	  	
     value: "Abdullahi Maikano College of Education, Wase",   	  	 	  	
    },
    {
     label: "Nosakhare College of Education, Benin City",   	  	 	  	
     value: "Nosakhare College of Education, Benin City",   	  	 	  	
    },
    {
     label: "Good Shepperd College of Education",   	  	 	  	
     value: "Good Shepperd College of Education",   	  	 	  	
    },
    {
     label: "Turath College of Education, Kano",   	  	 	  	
     value: "Turath College of Education, Kano",   	  	 	  	
    },
    {
     label: "Meadow Hall College of Education",   	  	 	  	
     value: "Meadow Hall College of Education",   	  	 	  	
    },
    {
     label: "Raphat College of Education",   	  	 	  	
     value: "Raphat College of Education",   	  	 	  	
    },
    {
     label: "Kazaure College of Education",   	  	 	  	
     value: "Kazaure College of Education",   	  	 	  	
    },
    {
     label: "JIBWIS College of Education",   	  	 	  	
     value: "JIBWIS College of Education",   	  	 	  	
    },
    {
     label: "Adamu Garkuwa College of Education, Toro",   	  	 	  	
     value: "Adamu Garkuwa College of Education, Toro",   	  	 	  	
    },
    {
     label: "Folrac Fortified College of Education, Ondo",   	  	 	  	
     value: "Folrac Fortified College of Education, Ondo",   	  	 	  	
    },
    {
     label: "Al-Iman College of Education",   	  	 	  	
     value: "Al-Iman College of Education",   	  	 	  	
    },
    {
     label: "Danyaya College of Education, Ningi",   	  	 	  	
     value: "Danyaya College of Education, Ningi",   	  	 	  	
    },
    {
     label: "Innovative College of Education, Karu",   	  	 	  	
     value: "Innovative College of Education, Karu",   	  	 	  	
    },
    {
     label: "Sam Ale College of Education",   	  	 	  	
     value: "Sam Ale College of Education",   	  	 	  	
    },
    {
     label: "Gombe State College of Education, NAFADA",   	  	 	  	
     value: "Gombe State College of Education, NAFADA",   	  	 	  	
    },
    {
     label: "Gand-Plus College of Education",   	  	 	  	
     value: "Gand-Plus College of Education",   	  	 	  	
    },
    {
     label: "Olekamba College of Education",   	  	 	  	
     value: "Olekamba College of Education",   	  	 	  	
    },
    {
     label: "Emamo College of Education",   	  	 	  	
     value: "Emamo College of Education",   	  	 	  	
    },
    {
     label: "Aminu Kano College of Islamic and Legal Studies",   	  	 	  	
     value: "Aminu Kano College of Islamic and Legal Studies",   	  	 	  	
    },
    {
     label: "Muhammad Goni College of Legal and Islamic Studies (MOGOLIS)",   	  	 	  	
     value: "Muhammad Goni College of Legal and Islamic Studies (MOGOLIS)",   	  	 	  	
    },
    {
     label: "A.D. Rufa�i College for Islamic and Legal Studies",   	  	 	  	
     value: "A.D. Rufa�i College for Islamic and Legal Studies",   	  	 	  	
    },
    {
     label: "Yusuf Bala Usman College of Legal and General Studies, Daura",   	  	 	  	
     value: "Yusuf Bala Usman College of Legal and General Studies, Daura",   	  	 	  	
    },
    {
     label: "Bauchi Institute for Arabic and Islamic Studies",   	  	 	  	
     value: "Bauchi Institute for Arabic and Islamic Studies",   	  	 	  	
    },
    {
     label: "Institute of Ecumenical Education (Thinkers Corner)",   	  	 	  	
     value: "Institute of Ecumenical Education (Thinkers Corner)",   	  	 	  	
    },
    {
     label: "Ameer Shehu Idris College of Advanced Studies, Zaria",   	  	 	  	
     value: "Ameer Shehu Idris College of Advanced Studies, Zaria",   	  	 	  	
    },
    {
     label: "Abuja School of Pension and Retirement Planning, APS Campus",   	  	 	  	
     value: "Abuja School of Pension and Retirement Planning, APS Campus",   	  	 	  	
    },
    {
     label: "Adecom College of Business Administration and Management Studies, Ibadan",   	  	 	  	
     value: "Adecom College of Business Administration and Management Studies, Ibadan",   	  	 	  	
    },
    {
     label: "Charkin Maritime Academy, Port Harcourt, Rivers State",   	  	 	  	
     value: "Charkin Maritime Academy, Port Harcourt, Rivers State",   	  	 	  	
    },
    {
     label: "College of Administration and Business Studies(CABSP),P.M.B. 1001, Potiskum, Yobe State",   	  	 	  	
     value: "College of Administration and Business Studies(CABSP),P.M.B. 1001, Potiskum, Yobe State",   	  	 	  	
    },
    {
     label: "College of Business and Management Studies,P. M. B. 003, Konduga, Borno State",   	  	 	  	
     value: "College of Business and Management Studies,P. M. B. 003, Konduga, Borno State",   	  	 	  	
    },
    {
     label: "College of Management and Business Studies, Abeokuta, Ogun State",   	  	 	  	
     value: "College of Management and Business Studies, Abeokuta, Ogun State",   	  	 	  	
    },
    {
     label: "Ed-John School of Management, Ikorodu, Lagos",   	  	 	  	
     value: "Ed-John School of Management, Ikorodu, Lagos",   	  	 	  	
    },
    {
     label: "Institute of Administration, ABU. Zaria",   	  	 	  	
     value: "Institute of Administration, ABU. Zaria",   	  	 	  	
    },
    {
     label: "Federal Cooperative College,Abuja Road.P.M.B. 2425, Kaduna",   	  	 	  	
     value: "Federal Cooperative College,Abuja Road.P.M.B. 2425, Kaduna",   	  	 	  	
    },
    {
     label: "Federal Cooperative College,P.M.B. 5033, Eleyele, Ibadan",   	  	 	  	
     value: "Federal Cooperative College,P.M.B. 5033, Eleyele, Ibadan",   	  	 	  	
    },
    {
     label: "Federal Cooperative College,Oji  River,P.M.B. 017, Enugu State",   	  	 	  	
     value: "Federal Cooperative College,Oji  River,P.M.B. 017, Enugu State",   	  	 	  	
    },
    {
     label: "Federal School of Social Works, Emene, Enugu",   	  	 	  	
     value: "Federal School of Social Works, Emene, Enugu",   	  	 	  	
    },
    {
     label: "Federal School of Statistics,P.O. Box 20753 U.I. Post Office, Ibadan",   	  	 	  	
     value: "Federal School of Statistics,P.O. Box 20753 U.I. Post Office, Ibadan",   	  	 	  	
    },
    {
     label: "Federal School of Statistics,Manchok,P.M.B 2043, Kaduna State",   	  	 	  	
     value: "Federal School of Statistics,Manchok,P.M.B 2043, Kaduna State",   	  	 	  	
    },
    {
     label: "Federal School of Surveying,Ogbomosho Road,P.M.B. 1024, Oyo",   	  	 	  	
     value: "Federal School of Surveying,Ogbomosho Road,P.M.B. 1024, Oyo",   	  	 	  	
    },
    {
     label: "Federal Training Centre,17 Marian Road, Calabar",   	  	 	  	
     value: "Federal Training Centre,17 Marian Road, Calabar",   	  	 	  	
    },
    {
     label: "Federal Training Centre,Emene, Industrial LayoutP.M.B. 01483, Enugu",   	  	 	  	
     value: "Federal Training Centre,Emene, Industrial LayoutP.M.B. 01483, Enugu",   	  	 	  	
    },
    {
     label: "Federal Training Centre,Ikoyi Road, Obalande, P.M.B. 12599, Marina-Lagos",   	  	 	  	
     value: "Federal Training Centre,Ikoyi Road, Obalande, P.M.B. 12599, Marina-Lagos",   	  	 	  	
    },
    {
     label: "Federal Training Centre,Kulende Housing Estate, Ilorin, Kwara State",   	  	 	  	
     value: "Federal Training Centre,Kulende Housing Estate, Ilorin, Kwara State",   	  	 	  	
    },
    {
     label: "Federal Training Centre,70 Isa Kaita Road, P.M.B. 2070, Kaduna",   	  	 	  	
     value: "Federal Training Centre,70 Isa Kaita Road, P.M.B. 2070, Kaduna",   	  	 	  	
    },
    {
     label: "Federal Training Centre,Custom Area, Bama Road, P.M.B 1594, Maiduguri, Borno State",   	  	 	  	
     value: "Federal Training Centre,Custom Area, Bama Road, P.M.B 1594, Maiduguri, Borno State",   	  	 	  	
    },
    {
     label: "Harvarde College of Management and Business Studies, Abeokuta, Ogun State",   	  	 	  	
     value: "Harvarde College of Management and Business Studies, Abeokuta, Ogun State",   	  	 	  	
    },
    {
     label: "Jigawa State Institute of Information Technology, Kazaure",   	  	 	  	
     value: "Jigawa State Institute of Information Technology, Kazaure",   	  	 	  	
    },
    {
     label: "Lapo Institute for Microfinance and Enterprise Development, Uselu, Benin City, Edo",   	  	 	  	
     value: "Lapo Institute for Microfinance and Enterprise Development, Uselu, Benin City, Edo",   	  	 	  	
    },
    {
     label: "Michael Imodu National Institute for Labour Studies,Ilorin, Kwara State",   	  	 	  	
     value: "Michael Imodu National Institute for Labour Studies,Ilorin, Kwara State",   	  	 	  	
    },
    {
     label: "Metallurgical Training Institute,KM. 6/7 Onitsha-Owerri Road,P.M.B. 1555, Onitsha",   	  	 	  	
     value: "Metallurgical Training Institute,KM. 6/7 Onitsha-Owerri Road,P.M.B. 1555, Onitsha",   	  	 	  	
    },
    {
     label: "National Water Resources Institute,Mando Road, P.M.B. 2309, Kaduna",   	  	 	  	
     value: "National Water Resources Institute,Mando Road, P.M.B. 2309, Kaduna",   	  	 	  	
    },
    {
     label: "Nigerian Army School of Finance and Administration,Apapa, Lagos",   	  	 	  	
     value: "Nigerian Army School of Finance and Administration,Apapa, Lagos",   	  	 	  	
    },
    {
     label: "Nigerian Army School of Signals,H/Q Arakan Barrack, Apapa-Lagos", 
     value: "Nigerian Army School of Signals,H/Q Arakan Barrack, Apapa-Lagos", 
    },
    {
     label: "National Institute for Nigerian Languages (NINLAN)",   	  	 	  	
     value: "National Institute for Nigerian Languages (NINLAN)",   	  	 	  	
    },
    {
     label: "Nigerian Institute of Journalism,Ikeja, Lagos",   	  	 	  	
     value: "Nigerian Institute of Journalism,Ikeja, Lagos",   	  	 	  	
    },
    {
     label: "Nigerian Institute of Leather and Chemical Technology, Zaria, Kaduna",   	  	 	  	
     value: "Nigerian Institute of Leather and Chemical Technology, Zaria, Kaduna",   	  	 	  	
    },
    {
     label: "Nigerian Navy College of Engineering, Sapele, Naval Base, Ogborode, Delta State",   	  	 	  	
     value: "Nigerian Navy College of Engineering, Sapele, Naval Base, Ogborode, Delta State",   	  	 	  	
    },
    {
     label: "Nigerian Navy Finance and Logistics College, Naval Base, Owerrinta, Abia State",   	  	 	  	
     value: "Nigerian Navy Finance and Logistics College, Naval Base, Owerrinta, Abia State",   	  	 	  	
    },
    {
     label: "National Institute for Legislative and Democratic Studies, Maitama, Abuja",   	  	 	  	
     value: "National Institute for Legislative and Democratic Studies, Maitama, Abuja",   	  	 	  	
    },
    {
     label: "Wavecrest College of Catering and Hospitality Management, Lagos",   	  	 	  	 	  	 	  	
     value: "Wavecrest College of Catering and Hospitality Management, Lagos",}	  	 	  	 	  	 	  	
]

export const states = [
    {
     itle: "Abia"},
    {title: "Adamawa"},
    {title: "Akwa-Ibom"},
    {title: "Anambra"},
    {title: "Bauchi"},
    {title: "Bayelsa"},
    {title: "Benue"},
    {title: "Borno"},
    {title: "Cross-River"},
    {title: "Delta"},
    {title: "Ebonyi"},
    {title: "Edo"},
    {title: "Ekiti"},
    {title: "Enugu"},
    {title: "Gombe"},
    {title: "Imo"},
    {title: "Jigawa"},
    {title: "Kaduna"},
    {title: "Kano"},
    {title: "Katsina"},
    {title: "Kebbi"},
    {title: "Kogi"},
    {title: "Kwara"},
    {title: "Lagos"},
    {title: "Nassarawa"},
    {title: "Niger"},
    {title: "Ogun"},
    {title: "Ondo"},
    {title: "Osun"},
    {title: "Oyo"},
    {title: "Plateau"},
    {title: "Rivers"},
    {title: "Sokoto"},
    {title: "Taraba"},
    {title: "Yobe"},
    {title: "Zamfara"},
    {title: "Abuja(FCT)"}
]




export const categories = [
    {title: "Apartment"},
    {title: "Phone"},
    {title: "Laptop"},
    {title: "Pot"},
    {title: "Dishes"},
    {title: "Plates"},
    {title: "Watches"},
    {title: "Playstation"},
    {title: "Games"},
    {title: "Generator"},
    {title: "Tv"},
    {title: "Gotv"},
    {title: "Dstv"},
    {title: "Tricycle"},
    {title: "Cars"},
    {title: "Electronics"},
    {title: "Electric appliances"},
    {title: "Home appliances"},
    {title: "Beds"},
    {title: "Clothes"},
    {title: "Furnitures"},
    {title: "Chairs"},
    {title: "Plastics"},
    {title: "Tables"},
    {title: "Bags"},
    {title: "Hairs"},
    {title: "Attachments"},
    {title: "Wigs"},
    {title: "handbag"},
    {title: "Chains"},
    {title: "Jewelries"},
    {title: "Shoes"},
    {title: "Footwears"},
    {title: "Foods"},
    {title: "Snacks"},
    {title: "Cooker"},
    {title: "Cylinders"},
    {title: "Automobiles"},
    {title: "Textbooks"},
    {title: "Materials"},
    {title: "Tutorials"},
    {title: "Perfumes"},
    {title: "Ebooks"},
    {title: "Skin Care"},
    {title: "Nails"},
    {title: "Body spray"},
    {title: "Relaxer"},
    {title: "Services"},
    {title: "Jobs"},
    // {title: "Body spray"},
    // {title: "Body spray"},
]

export const Categories = [
    {
       name: "Students Need",
       items:[
        "Room Mates",
        "Apartments",
        "Laptops",
        "Desktops",
        "Cameras",
        "Beds",
        "Clothes",
        "Textbooks",
        "Online Courses",
        "Learning Apps",
        "Study Guides",
        "Generators",
        "Kitchen Accesssories",
        "Home appliances",
        "Bags",
        "Containers and Vessels",
        "Footwears",
        "E-books",
        "Cylinders",
        "Cookers",
        "Tutorials",
    ]
},
    {
        name: "Electronics",
        items:[
        "Mobile Phones",
        "Gadgets",
        "Tablets",
        "Laptops",
        "Desktops",
        "Cameras",
        "Drones",
        "Computer Components",
        "Solar Panels",
        "Inverters",
        "Powerbanks",
        "Generators",
        "Energy Meters",
        "Water Heaters",
        "Lenses",
        "Memory Cards",
        "Photo Printers",
        "Microphones",
        "Headphones",
        "Soundproofing Materials",
        "Cables & Accessories",
        "High-End Blenders",
    ]
},
    {
        name: "Fashion, Luxury & Travels",
        items:[
        "Men's Clothing",
        "Women's Clothing",
        "Footwear",
        "Jewelry",
        "Watches",
        "Bags & Wallets",
        "Sunglasses",
        "Hats & Caps",
        "Luggages",
        "Travel Accessories",
        "Passport Holders",
        "Designer Clothing",
        "Luxury Handbags",
        "Luxury Perfumes",
        "Designer Shoes",
        "Maternity Wear",
        "Plus Size Clothing",
        "Lingerie",
        "Costumes",
        "Chef's Uniforms",
        "Medical Lab Coats",
    ]
},
    {
        name: "Hardware & Home Materials",
        items:[
        "Furniture",
        "Home Decor",
        "Kitchen Appliances",
        "Cookware",
        "Bedding",
        "Bath",
        "Lighting",
        "Cleaning Supplies",
        "Tools & Home Improvement",
        "Paint & Wall Treatments",
        "Flooring",
        "Plumbing",
        "Electrical",
        "Building Materials",
        "Hardware",
        "Wallpaper",
        "Tile & Flooring",
        "Cabinets & Countertops",
        "Doors & Windows",
        "Roofing Materials",
        "Chef's Knives",
        "Baking Tools",
    ]
},
    {
        name: "Health, Beauty & Emergency",
        items:[
        "Skincare",
        "Haircare",
        "Makeup",
        "Fragrances",
        "Personal Care",
        "Health Supplements",
        "Medical Supplies",
        "Fitness Equipment",
        "Oral Care",
        "Men’s Grooming",
        "Pain Killers",
        "Tablets",
        "Energy Boosters",
        "Birth Control",
        "Sexually Related Drugs",
        "Children Drugs",
        "Body Cleansers",
        "First Aid Kits",
        "Survival Kits",
        "Personal Protective Equipment",
        "Emergency Tools",
    ]
},
    {
        name: "Sports & Entertainment",
        items:[
        "Exercise & Fitness",
        "Outdoor Recreation",
        "Team Sports",
        "Individual Sports",
        "Cycling",
        "Fishing",
        "Water Sports",
        " Movies",
        "TV Shows",
        "Concert Tickets",
        "Theater Tickets",
        "Sports Tickets",
        "Music Festivals",
    ]
},
    {
        name: "Books & Media",
        items:[
        "Fiction Books",
        "Non-Fiction Books",
        "E-books",
        "Audiobooks",
        "Magazines",    
        "CDs, DVDs & Blu-rays",
        "Comics & Graphic Novels",
        "Educational Media",
        "Streaming Services",
        "Online Courses",
        "Live Streaming Events",
        "Textbooks",
        "Workbooks",
        "Science Kits",
        "Language Learning Tools",
        "Classroom Supplies",
        "Printable Templates",
        "Digital Photography",
        "Digital Patterns",
        "Subscription Services",
    ]
},
    {
        name: "Toys And Games",
        items:[
        "Gaming Consoles",
        "Action Figures",
        "Dolls & Accessories",
        "Building Sets",
        "Educational Toys",
        "Board Games",
        "Video Games",
        "Handheld Games",
        "Outdoor Play Equipment",
        "Remote Control Toys",
        "Adult Toys",
        "Science Kits",
        "Math Games",
        "Language Learning Toys",
        "Puzzle Toys",
        "Musical Toys",
        "Robotics Kits",
        "Strategy Games",
        "Family Games",
        "Party Games",
        "Card Games",
        "Cooperative Games",
        "Role-Playing Games",
        "Classic Games",
        "Puzzle Games",
        "Game Accessories",
    ]
},
    {
        name: "Automotive",
        items:[
        "Cars",
        "Motorcycles",
        "Tricycles",
        "Trucks",
        "Vehicle Parts",
        "Other Automobiles",
        "Other Automobiles spare parts",
        "General spare parts",
        "Car Accessories",
        "Motorcycle Accessories",
        "Tricycle Accessories",
        "Motorcycle Parts",
        "Car Care",
        "Tools & Equipment",
        "Tires & Wheels",
        "GPS & Navigation",
        "Bicycles & Accessories",
        "Car Lifts",
        "Tire Inflators",
        "Car Wash Equipment",
        "Garage Equipment",
    ]
},
    {
        name: "Babies, Kids, Pets & Animals",
        items:[
        "Baby Clothing",
        "Baby Gear",
        "Diapering",
        "Feeding",
        "Nursery",
        "Baby Toys",
        "Kids’ Clothing",
        "Kids’ Shoes",
        "School Supplies",
        "Kids’ Furniture",
        "Pet Food",
        "Pet Toys",
        "Pet Grooming",
        "Pet Health Care",
        "Pet Beds",
        "Pet Carriers",
        "Pet Clothing",
        "Pet Training",
        "Aquariums & Accessories",
        "Small Animal Supplies",
    ]
},
    {
        name: "Occasions & Events",
        items:[
        "Gift Baskets",
        "Greeting Cards",
        "Party Supplies",
        "Wedding Supplies",
        "Seasonal Decor",
        "Flowers",
        "Gift Wrapping Supplies",
        "Tents & Canopies",
        "Portable Coolers",
        "Folding Chairs",
        "Bridal Accessories",
        "Wedding Dresses",
        "Bridesmaid Dresses",
        "Wedding Invitations",
        "Wedding Decorations",
        "Wedding Jewelry",
        "Wedding Shoes",
        "Wedding Gifts",
        "Balloons", 
        "Baby Shower Supplies",
        "Anniversary Supplies",
        "Graduation Supplies",
        "Religious Event Supplies",
        "Festival Supplies",
        "Concert Supplies",
    ]
},
    {
        name: "Industrial And Scientific",
        items:[
        "Lab Equipment",
        "Safety Equipment",
        "Test & Measurement Tools",
        "Industrial Supplies",
        "Janitorial Supplies",
        "Material Handling",
        "Power Transmission",
        "Plumbing",
        "Electrical",
        "Heavy Machinery",
        "Industrial Tools",
        "Safety Gear",
        "Material Handling",
        "Power Tools",
        "Welding Equipment",
        "Pumps & Compressors",
        "Medical Equipment",
        "Dental Supplies",
        "Veterinary Equipment",
        "Barber & Salon Supplies",
        "Spa Equipment",
        "Restaurant Equipment",
        "Photography Equipment",
        "Film Production Gear",
        "Construction Tools",
        "Laboratory Instruments",
    ]
},
    {
        name: "Farm & Gardening",
        items:[
        "Plants & Seeds",
        "Gardening Tools",
        "Outdoor Furniture",
        "Grills & Outdoor Cooking",
        "Outdoor Lighting",
        "Planters & Garden Decor",
        "Watering Equipment",
        "Pest Control",
        "Fertilizers",
        "Lawn Care",
        "Outdoor Storage",
    ]
},
    {
        name: "Arts, Craft & Music",
        items:[
        "Painting,Drawing & Craft Supplies",
        "Sewing & Knitting",
        "Beading & Jewelry Making",
        "Printmaking",
        "Fabric & Textile Crafts",
        "Audio Equipment",
        "Guitars",
        "Keyboards & Pianos",
        "Drums & Percussion",
        "Brass Instruments",
        "DJ Equipment",
        "Recording Equipment",
        "Amplifiers & Effects",
        "Instrument Cases",
        "Music Stands",
        "Metronomes",
        "Tuners",
        "Amplifiers",
        "African Art",
        "Continental Art",
        "Oceanic Art",
        "Religious Art",
        "Tribal Art",
    ]
},
    {
        name: "Groceries & Foods",
        items:[
        "Farm Produce",
        "Raw Foods",
        "Beverages",
        "Snacks",
        "Baking Supplies",
        "Canned & Packaged Goods",
        "Dairy & Eggs",
        "Meat & Seafood",
        "Organic Products",
        "Gluten-Free Products",
        "Gourmet Gifts",
        "Cooked Food",
        "Fries",
        "Baked Foods",
        "Drinks",
        "Barbeques & Sharwarma",
        "Fast Foods",
        "Packaged Foods",
        "Herbal Foods",
    ]
},
    {
        name: "Office & Religious Supplies",
        items:[
        "Office Furniture",
        "Stationery",
        "Filing & Organization",
        "Office Decor",
        "Printers & Scanners",
        "Ink & Toner",
        "Bibles & Christian Books",
        "Quran & Islamic Books",
        "Religious Jewelry",
        "Prayer Rugs",
        "Rosaries & Beads",
        "Religious Icons",
        "Religious Candles",
        "Native Items",
        "Religious Clothing",
        "Devotional Music",
        "Religious Home Decor",
    ]
},
    {
        name: "Safety And Security",
        items:[
        "Home Security Systems",
        "Surveillance Cameras",
        "Fire Safety",
        "Personal Safety",
        "Car Safety",
        "Child Safety",
        "Locks & Locking Devices",
        "Safes",
        "Emergency Kits",
        "Security Lighting",
    ]
},
    {
        name: "Services",
        items:[
        "Home Cleaning Services",
        "Home Management Services",
        "Personal Training",
        "Event Planning",
        "Pet Grooming",
        "Landscaping",
        "Tutoring",
        "Handyman Services",
        "Beauty Services",
        "Photography Services",
        "Tech Support",
        "Health Care",
        "Virtual Top-up",
        "Transportation",
        "General Services",
    ]
},
    {
        name: "Real Estate",
        items:[
        "Commercial Property for Rent",
        "Houses and Apartments for Rent",
        "Houses and Apartments for Sale",
        "Commercial Property for Sale",
        "Plots and Lands for Rent",
        "Plots and Lands for Sale",
        "Short-let Properties",
        "Events Centers, Venues and Work Stations",
    ]
},
    {
        name: "Jobs_and_Gigs",
        items:[
        "Administrative & Office Support",
        "Finance & Accounting",
        "Healthcare & Medical",
        "Information Technology",
        "Education & Training",
        "Marketing & Advertising",
        "Sales & Retail",
        "Engineering",
        "Human Resources",
        "Creative & Design",
        "Legal",
        "Operations & Logistics",
        "Manufacturing & Production",
        "Construction & Trades",
        "Hospitality & Tourism",
        "Customer Service",
        "Science & Research",
        "Media & Communication",
        "Real Estate",
        "Transportation",
        "Environmental & Agriculture",
        "Social Services & Non-Profit",
        "Research",
        "Public Relation",
    ]
},
]
