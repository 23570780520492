import React, { useEffect, lazy, Suspense} from 'react';
import { Pageloader } from "./Modals/Loaders";
import { BrowserRouter, Routes, Route,  } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import Layout from './Components/Layout';
import {About} from './Pages/About';
import {Yourreferrals} from './Pages/Accessories/Yourreferrals';
import Contact from './Pages/Contact';
import {Signup, Signin, Forgotpassword, Resetpassword} from './Pages/Signup';
import EmploymentForm from './Pages/Useguide';
import FAQs from './Pages/FAQs';
import { Createproductpage } from './Pages/Createproductpage'
import {  Editproductpage } from './Pages/Editproductpage';
import {  Yourproductspage } from './Pages/Yourproductspage';
import Newproduct from './Pages/Newproduct';
import Search from './Pages/Search';
import Mainpage from  './Pages/Accessories/Mainpage';
import {Trendingproductpage, Productpage } from   './Pages/Productpage';
import Store from './Pages/Links/Store';
import { Signuplink } from './Pages/Links/Signuplink';

const Firstpage = lazy(() => import("./Pages/Firstpage"));


function App() {
  let university = JSON.parse(localStorage.getItem("universities"));

  if (!university) {
      university = {
        label: "All Universities",
        value: "All Universities",
       };
      localStorage.setItem("universities", JSON.stringify(university));
  }
 
  return (
    <>
    
    <BrowserRouter>
    <Routes>
    <Route
          path="/"
          element={
            <Suspense fallback={<Pageloader/>}>
              <Firstpage />
            </Suspense>
          }
        />
        <Route path="/" element={<Layout />}>
<Route path='/trendingproductpage/:slug/:id' element={<Trendingproductpage />}/>
<Route path='/productpage/:slug/:id' element={<Productpage />}/>
<Route path='/yourproductspage/:title/:id' element={<Yourproductspage />}/>
<Route path='/newproduct/:title/:id' element={<Newproduct />}/>
{/* <Route path='/mystore/:id' element= {<Mystore />}/> */}
        </Route>
        <Route path='/store/:id' element={<Store />}/>
        <Route path='/signuplink/:id' element={<Signuplink />}/>
        <Route path='/editproductpage/:id/:title' element={<Editproductpage />}/>
        <Route path='/search/:university/:title' element= {<Search />}/>          
        <Route path='/mainpage/:id' element= {<Mainpage />}/>
        <Route path='/signup' element= {<Signup />}/>
        <Route path='/signin' element= {<Signin />}/>
        <Route path='/forgotpassword' element= {<Forgotpassword />}/>
        <Route path='/resetpassword' element= {<Resetpassword />}/>
        <Route path='/about' element= {<About />}/>
        <Route path='/yourreferrals' element= {<Yourreferrals />}/>
        <Route path='/contact' element= {<Contact />}/>
        <Route path='/faqs' element= {<FAQs />}/>
        <Route path='/createproductpage' element= {<Createproductpage />}/>
        <Route path='/useguide' element= {<EmploymentForm />}/>
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
